/* ********** Container Banner Blog ********** */
.container-banner-blog{
    /*text-align:center;
    height:calc((100vw * 360) / 1440);
    padding-top:10.5rem;
    padding-bottom:12rem;*/

    .content-center{
        position:absolute;
        left:50%;
        top:50%;
        @include transformTranslate(-50%, -50%);
    }
    
    /*.swiper-container-custom-02{
        .swiper-container{
            height: 115vh;
            
            .swiper-slide{
                &:before{
                    height: calc(100% - 50vh);
                    opacity: 0.8;
                }
            }
            
            .content-image{
                height:calc(100vh - 35px);
                
                &:before{
                    height:95%;
                }
            }
        }
        
        .arrow-slider{
            bottom:42vh;
        }
        
        .col-text{

        }
    }*/
    
    .swiper-container-custom-02{
        .swiper-pagination{
            display:none;
        }
    }
    
    .container-image-text-inline{
        .headline-text{
            left:calc((100vw - 1585px) / 2);
            top:calc(100vh - 110px);
            z-index:100;
        }
    }
}

/* ********** Container Top Blog ********** */
.container-top-blog{
    position:relative;
    padding-top:6rem;
    font-family:$second-font;

    .content-cta-left{
        position:absolute;
        left:0;
        top:0;

        .text-vertical-absolute:before{
            background-color:$second-color;
            opacity:0.2;
        }
    }

    .btn-square-arrow{
        left:0;
        top:0;
    }

    .small-title, .info-post{
        @extend .text-small-01;
        font-size:1.8rem !important;
        color:$second-color-darken;
        margin-bottom:2.6vh;
        display:inline-block !important;
    }

    h1{
        @extend .text-title-02;
        font-weight:400 !important;
        margin-bottom:3rem;
    }

    .container-top-blog{
        .info-post, .small-title{
            @include base-text(1.5rem, 500, 1.2em, 0.5px, uppercase);
            color:$second-color;
            display:inline-block;
            margin-bottom:3rem;

            strong{
                @include base-text(1.6rem, 300, 1.2em, 0.5px, uppercase);
                margin-left:0.6rem;
            }
        }
    }
}


/* ********** Container Wrapper Blog ********** */
.container-wrapper-blog{
    position:relative;
    z-index:9;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        height:100vh;
        z-index:-1;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(241,246,250,1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(241,246,250,1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(241,246,250,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$white, endColorstr=$light-color,GradientType=0 );
    }

    .container-large{
        width:$container-small;
    }

    .container-carousel-large{
        margin-bottom:9vh;
    }

    .container-image-text-inline{
        padding-top:0 !important;
        padding-bottom:0 !important;

        .row{
            padding-top:0 !important;
            padding-bottom:0 !important;
        }
    }
    
    .container-text-center{
        padding-top:2vh;
        padding-bottom:5vh;
        
        .title-center{
            color:$second-color-darken;
        }
        
        p{
            padding-bottom:2.6vh;
        }
        
        h3{
            margin-bottom:5vh;
        }
    }
}


/* ********** Container Wrapper Listing Blog ********** */
.container-wrapper-listing-blog{
    color:$main-color;
    padding-top:2vh;
    padding-bottom:4.5vh;

    figure{
        margin-bottom:4.25vh;
    }

    .big-title{
        @extend .text-title-04, .text-title-04.lh-less;
        font-size:2.8rem !important;
        margin-bottom:2vh;
    }

    .sub-text{
        @extend .text-small-01;
        color:$second-color-darken;
        display:block;
        letter-spacing:1px !important;
        margin-bottom:1.25vh;
    }

    p{
        @extend .text-p-03;
        margin-bottom:2.5vh;
    }

    .container-c{
        position:relative;
        z-index:9;
        overflow:hidden;

        &:before, &:after{
            content:" ";
            position:absolute;
            left:-11px;
            top:0;
            width:calc(100% + 22px);
            height:4vh;
            background-color:$white;
            z-index:91;
        }

        &:after{
            top:auto;
            bottom:0;
            height:6vh;
        }

        .line-c-left, .line-c-right{
            position:absolute;
            left:-10px;
            top:0;
            width:1px;
            height:100%;
            z-index:9;
            background-color:$white;
        }

        .line-c-right{
            left:auto;
            right:-11px;
        }
    }

    .row{
        margin-left:-3rem;
        margin-right:-3rem;

        .col{
            padding:4vh 3rem 7vh;
            border-bottom:1px solid #dae1e5;
            position:relative;

            &:before, &:after{
                content:" ";
                position:absolute;
                left:-0.5px;
                top:0;
                width:1px;
                height:100%;
                background-color:#dae1e5;
            }

            &:after{
                left:auto;
                right:-0.5px;
            }
        }
    }
    
    @media screen and (max-width: 1440px){
        .big-title{
            font-size:2.4rem !important;
        }
    }
}


/* ********** Container Nav Blog ********** */
.container-nav-blog{
    text-align:center;
    padding-top:4.5rem;
    padding-bottom:6rem;
    background-color:#eef1f3;
    margin-top:3rem;

    .page-numbers{
        @include base-text(2.2rem, 300, 1.2em);
        color:$second-color;
        padding-left:0.8rem;
        padding-right:0.8rem;
        margin-left:0.4rem;
        margin-right:0.4rem;
        @include transition();
        position:relative;
        padding-bottom:1.5rem;
        top:0;

        &:before{
            content:" ";
            position:absolute;
            left:50%;
            bottom:0;
            width:0;
            height:0.3rem;
            background-color:$second-color;
            @include transition();
        }

        &.dots{
            letter-spacing:4px;
        }

        &.prev, &.next{
            color:$second-color;
            @include base-text(2rem, 300, 1.2em);
            top:0;

            svg{
                position:absolute;
                width:2.2rem;
                height:1.7rem;
                padding:0.1rem;
                left:0;
                top:32%;
                @include transformTranslateY(-50%);
                fill:$second-color;
                @include transition();
            }

            /*span{
                position:relative;
                padding-bottom:1.2rem;

                &:before{
                    content:" ";
                    position:absolute;
                    left:0;
                    bottom:0;
                    width:100%;
                    height:1px;
                    background-color:$second-color;
                }
            }*/

            &:before{
                display:none;
            }

            &:after{
                content:" ";
                position:absolute;
                left:auto;
                right:4rem;
                top:24%;
                width:3.4rem;
                height:1px;
                background-color:$second-color;
            }
        }

        &.prev{
            padding-left:4.5rem;
            padding-right:12rem;
        }

        &.next{
            padding-left:12rem;
            padding-right:4.5rem;

            svg{
                left:auto;
                right:0;
            }

            &:after{
                left:4rem;
            }
        }

        &.current{
            color:$main-color;

            &:before{
                width:100%;
                left:0;
                background-color:$main-color;
            }
        }
    }

    @media screen and (max-width: 1050px){
        .page-numbers{
            &.prev, &.next{
                &:after{
                    display:none;
                }
            }

            &.prev{
                padding-right:3rem;
            }

            &.next{
                padding-left:3rem;
            }
        }
    }

    @media screen and (max-width: 900px){
        .page-numbers{
            &.prev{
                padding-left:5rem;
            }

            &.next{
                padding-right:5rem;
            }
        }
    }

    @media screen and (max-width: 800px){
        .page-numbers{
            font-size:2rem;
            padding-left:0.6rem;
            padding-right:0.6rem;
            margin-left:0.2rem;
            margin-right:0.2rem;

            &.prev, &.next{
                font-size:1.4rem;
            }

            svg{
                width:2.8rem;
                height:2.8rem;
            }
        }
    }

    @media screen and (max-width: 700px){
        .page-numbers{
            font-size:1.6rem;
            padding-left:0.4rem;
            padding-right:0.4rem;
            margin-left:0;
            margin-right:0;
            top:0.25rem;

            &.prev, &.next{
                font-size:1.2rem;
            }
        }
    }

    @media screen and (max-width: 600px){
        .page-numbers{
            top:0;

            &.prev, &.next{
                span{
                    display:none;
                }

                svg{
                    width:3.2rem;
                    height:3.2rem;
                }
            }

            &.next{
                padding-left:0;
            }

            &.prev{
                padding-right:0;
            }
        }
    }
}

&.no-touchevents .container-nav-blog{
    .page-numbers:not(.dots):hover{
        color:$main-color !important;

        svg{
            fill:$main-color;
        }

        &:before{
            width:100%;
            left:0;
        }

        &.prev, &.next{
            color:$second-color;
        }
    }
}

.swiper-container-custom-02{
    .swiper-container-horizontal,
    .swiper-container-vertical{

        .swiper-pagination{
            background-color: rgba($main-color, 0.8);
            padding: 3rem 1.5rem 4.5rem;
            right: 0;
            text-align: center;
            width: 8rem;
            @media screen and (max-width: 650px){
                display: none;
            }
        }
        .swiper-pagination-bullet{
            background: transparent;
            border-radius: 0;
            color: $second-color;
            font-weight: bold;
            line-height: 5rem;
            margin: 0 auto;
            opacity: 1;
            height: auto;
            width: auto;
        }
        .swiper-pagination-bullet-active{
            background: transparent;
            color : $white;

            &:after{
                background-color: $light-gray;
                content: " ";
                display: block;
                position: relative;
                left: 80%;
                top: -2.5rem;
                height: 2px;
                width: 3.5rem;
            }
        }
    }
}