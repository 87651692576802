/* ********** Container Double Zone Content Offset ********** */
.container-double-zone-content-offset{
    background-color:$light-color;
    position:relative;
    z-index:99;
    font-family:$second-font;

    h2{
        @extend .text-title;
        margin-bottom:5vh;
    }

    h3{
        @extend .text-medium-title-01;
        margin-bottom:3.6vh;
    }

    p{
        @extend .text-p-01;
        margin-bottom:7vh;
    }

    ul{
        @extend .list-circle;
        margin-bottom:7vh;
    }
    
    .btn-arrow-right{
        margin-bottom:3vh;
    }

    hr{
        background-color:rgba(113,168,210,0.3);
        margin-bottom:5.5vh;
        width:calc(100% + 6vw);
        position:relative;
        left:-6vw;
    }

    .col{
        padding:10vh 6vw 6vh;
    }

    .col-left{
        background-color:$white;
        color:$main-color;
        margin-top:-20vh;
        position:relative;
        z-index:9;

        &:before{
            content:" ";
            position:absolute;
            right:-3vw;
            top:0;
            width:3vw;
            height:100%;
            background-color:$white;
        }

        h3{
            color:$second-color-darken;
        }
    }

    .col-right{
        background-color:$main-color;
        color:$white;
        padding-left:9vw;

        hr{
            width:calc(100% + 9vw);
            left:-9vw;
            background-color:rgba(255,255,255,0.3);
        }
    }

    .col-center{
        text-align:center;
        background: rgb(219,232,241);
        background: -moz-linear-gradient(left, rgba(219,232,241,1) 0%, rgba(237,243,246,1) 100%);
        background: -webkit-linear-gradient(left, rgba(219,232,241,1) 0%,rgba(237,243,246,1) 100%);
        background: linear-gradient(to right, rgba(219,232,241,1) 0%,rgba(237,243,246,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dbe8f1', endColorstr='#edf3f6',GradientType=1 );
        /*padding-left:4vw;
        padding-right:4vw;*/

        /*&:before, &:after{
            content:" ";
            position:absolute;
            left:-2vw;
            background-color:$light-color;
        }

        &:before{
            top:0;
            width:2vw;
            height:calc(100% + 2vw);
        }

        &:after{
            top:100%;
            width:calc(100% + 2vw);
            height:2vw;
        }*/

        .text-title{
            @extend .text-title-03;
            margin-bottom:5vh;
        }

        hr{
            width:100%;
            left:0;
            margin-bottom:6vh;
        }
        
        &.variant-text-left{
            text-align:left;
            background:none;
            
            &:before, &:after{
                display:none;
            }
        }
    }

    .content-img-sceau{
        position:absolute;
        left:0;
        top:13vh;
        @include transformTranslateX(-50%);
    }

    &.container-double-zone-content-offset-2{
        h3{
            @extend .text-title-04, .text-title-04.fw-less;
            margin-bottom:3vh;
        }

        .col-left{
            background-color:transparent;
            padding-top:13vh;
            padding-bottom:8rem;

            &:before{
                display:none;
            }
        }
    }
    
    @media screen and (max-width: 767px){
        .col-left{
            margin-top:0;
            padding-top:6vh;
            padding-bottom:3rem;
            
            &:before{
                display:none;
            }
        }
        
        .col-right{
            padding-bottom:2vh;
        }
        
        &.container-double-zone-content-offset-2{
            .col-left{
                padding-top:6vh;
                padding-bottom:0;
            }
        }
    }
}
