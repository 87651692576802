.container-double-text-logo-small-text{
    color:$second-color-darken;
    padding-top:5vh;
    padding-bottom:3vh;
    font-family:$second-font;
    position:relative;
    z-index:9;
    
    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:calc(50vw - 5px);
        height:100%;
        background-color:$light-color;
        z-index:-1;
    }
    
    h3{
        @extend .text-title-04, .text-title-04.fw-less;
        margin-bottom:6vh;
    }
    
    p{
        @extend .text-p-03;
        margin-bottom:2.8vh;
        color:$main-color;
    }
    
    .col{
        padding-top:7vh;
        padding-bottom:5vh;
        padding-right:8vw;
        padding-left:9vw;
        position:relative;
        z-index:9;
        
        &.col-left{
            &:before{
                content:" ";
                position:absolute;
                left:-20px;
                top:0;
                width:calc(100% + 40px);
                height:100%;
                background-color:$light-color;
                z-index:-1;
                opacity:0;
            }
        }
    }
    
    .text-small{
        @extend .text-small-02, .text-small-02.smaller;
        padding-top:3vh;
        margin-bottom:1.8vh;
    }
    .text-large{
        @extend .link-tel;
    }
    
    .text-medium{
        @extend .text-title-01, .text-title-01.fw-less;
    }
    
    hr{
        margin-top:9.5vh;
        margin-bottom:9.5vh;
    }
    
    .content-img-sceau{
        position:absolute;
        top:0;
        left:0;
        @include transformTranslate(-50%, 50%);
    }
    
    @media screen and (max-width: 992px){
        .col{
            padding-left:6vw;
            padding-right:5vw;
        }
    }
    
    @media screen and (max-width: 767px){
        padding-top:0;
        padding-bottom:3rem;
        
        &:before{
            display:none;
        }
        
        .col{
            padding-left:0;
            padding-right:0;
            
            &.col-left{
                &:before{
                    opacity:1;
                }
            }
        }
    }
}