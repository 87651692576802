#gallery-container{
    position: relative;
    z-index:999;
    .portfolio_masonry_item{
        padding:20px;
        .image_holder{
            overflow:hidden;
            position:relative;
            img{
                width: 100%;
                @include transition();
            }
            
            a{
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                    .feature_holder{
                        @include transition();
                        display: block !important;
                    }
                }
                
                                
                &.videotype{
                    .feature_holder_icons{
                        font-size: 1.2rem;
                        padding-left:0.2em;
                    }
                }
            }
            
            .text_holder{
                position: absolute;
                top:0px;
                left:0px;
                width: 100%;
                height: 100%;
            }
            
            .feature_holder{
                position: absolute;
                top:50%;
                left:50%;
                display:none;
                background:rgba(0,0,0,0.8);
                margin-left:-30px;
                margin-top: -30px;
                height: 60px;
                width: 60px;
                text-align: center;
                @include border-radius(50%);
                .feature_holder_icons{
                    font-size: 1.6rem;
                    color: #fff;
                    line-height: 60px;
                }
            }
        }
    }
}

.mfp-counter{
    display: none;
}

.filter_holder{
    text-align:center;
    ul{
        display: inline-block;
        li{
            float: left;
            padding: 20px;
            cursor: pointer;
            color:#000;
            font-weight: bold;
            @include transition();
            &.is-checked{
                text-decoration: underline;
            }
            &:hover{
                opacity: 0.7;
            }
        }
    }
}