/* ********** Container Text Double Col ********** */
.container-text-double-col{
    position:relative;
    margin-bottom:7rem;

    .list-dot{
        ul{
            max-width:56rem;
            display:block;
            margin-left:auto;
            margin-right:auto;

            li{
                font-weight:300;
            }
        }
    }

    @media screen and (max-width: 992px){
        margin-bottom:5.5rem;
    }

    @media screen and (max-width: 659px){
        margin-bottom:4rem;
    }

    @media screen and (min-width: 767px){
        &:before{
            content:" ";
            position:absolute;
            left:50%;
            width:1px;
            height:100%;
            background-color:#e5e7e9;
        }
    }
}