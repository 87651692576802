/* ********** Container Carousel Large ********** */
.container-carousel-large{
    .swiper-container{
        height:90vh;
    }
    
    .arrow-slider{
        position:absolute;
        left:auto;
        top:auto;
        right:0;
        bottom:0;
        
        &.arrow-slider-left{
            @include transformTranslateX(-100%);
        }
    }
}