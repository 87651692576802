/* ********** Content Form ********** */
.content-form{
    overflow:hidden;
    padding-top:2rem;
    
    .row{
        margin-left:-3.5rem;
        margin-right:-3.5rem;
        
        .col{
            padding-left:3.5rem;
            padding-right:3.5rem;
            margin-bottom:4rem;
        }
    }
    
    label, input:not([type="submit"]), textarea, .select2-container .select2-selection{
        @include base-text(1.5rem, 400, 1.2em, 2px, uppercase);
        border:none;
    }

    label{
        /*color:#acc5e5;*/
        color:$second-color-lighten;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        padding:1.5rem 0.8rem 1.5rem;
        text-align:left;
        text-transform:uppercase !important;
        z-index:9;
        @include transition();
    }

    textarea{
        border:none;
        background-color:transparent;
    }

    input:not([type="submit"]), textarea, .select2-container .select2-selection{
        font-family:$main-font;
        width:100%;
        border-bottom:1px solid #c8c8c8;
        padding:1.5rem 0.8rem 1.5rem;
        display:inline-block;
        color:#828282;
        @include border-radius(0);
        /*@include transition();*/
        outline:0;
    }
    
    .select2-container{
        width:100% !important;
        
        .select2-selection{
            height:auto;
            text-align:left;
        }
        
        .select2-selection__rendered{
            line-height:inherit;
            padding-left:0;
            color:#9dc3df;
        }
        
        .select2-selection__arrow{
            top:2rem;
            right:3rem;
            width:1.9rem;
            height:1rem;
            background-image:url(../images/svg-arrow-select-2.svg);
            background-repeat:no-repeat;
            
            b{
                display:none;
                @include transition();
            }
        }
    }

    textarea{
        height:10rem;
    }

    input[type="submit"]{
        cursor:pointer;
        outline:0;
        -webkit-appearance:none;
        appearance:none;
    }

    .over-input{
        position:relative;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            bottom:0;
            width:0;
            height:2px;
            background-color:$second-color;
            @include transition();
            z-index:999;
        }

        &.active{
            label{
                color:$second-color-darken2;
            }

            &:before{
                width:100%;
            }

            ::-webkit-input-placeholder{
                color:#acc5e5;
            }
            ::-moz-placeholder{
                color:#acc5e5;
            }
            :-ms-input-placeholder{
                color:#acc5e5;
            }
            :-moz-placeholder{
                color:#acc5e5;
            }
        }

        &.active, &.not-empty{
            label{
                top:-2.1rem;
                /*padding-left:0;*/
                font-size:1rem;
            }
        }
        
        small{
            position:absolute;
            left:0;
            width:100%;
            top:0;
            font-size:1.2rem;
            text-align:left;
            padding-left:1rem;
            font-weight:400;
        }
    }

    .over-input.textarea{
        &:before{
            bottom:3px;
        }
    }

    ::-webkit-input-placeholder{
        color:transparent;
        text-transform:uppercase;
    }
    ::-moz-placeholder{
        color:transparent;
        text-transform:uppercase;
    }
    :-ms-input-placeholder{
        color:transparent;
        text-transform:uppercase;
    }
    :-moz-placeholder{
        color:transparent;
        text-transform:uppercase;
    }

    .wpcf7-mail-sent-ok, .wpcf7-validation-errors{
        display:inline-block;
        padding:1.5rem 2rem;
        margin:0;
        margin-bottom:2.5rem;
        @include base-text(1.2rem, 500, 1.2em, 1px);
    }

    .wpcf7-form-control-wrap{
        display:block;
    }

    .wpcf7-not-valid-tip{
        position:relative;
        color:#ff0042;
        position:absolute;
        top:calc(100% - 2px);
        padding-top:1.25rem;
        padding-left:0;
        @include base-text(1.1rem, 300, 1.2em, 1px, uppercase);
        background-image:url(../images/form-icon-info.svg);
        background-size:1.8rem 1.8rem;
        background-repeat:no-repeat;
        background-position:left 0 bottom 15%;
        padding-left:4rem;
        z-index:9999999;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:2px;
            background-color:#ff4c4c;
        }
    }

    @media screen and (max-width: 992px){
        label, input:not([type="submit"]), textarea{
            font-size:1.4rem;
        }
    }

    @media screen and (max-width: 650px){
        label, input:not([type="submit"]), textarea{
            font-size:1.3rem;
        }
    }
}

&.touchevents .content-form{
    .over-input{
        &:before{
            bottom:1px;
        }

        &.textarea{
            &:before{
                bottom:3px;
            }
        }
    }
}