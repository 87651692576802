/* ********** Condition Custom Blocks ********** */
/*.swiper-container-custom-01 + .container-text-small-image{
    margin-top:-16vh;
}*/

/*.container-image-text-inline + .content-background-image{
    padding-bottom:0 !important;
}

.container-image-text-inline + .container-text-center{
    padding-top:0 !important;
    padding-bottom:0 !important;
}

.container-text-center + .content-background-image{
    padding-bottom:0 !important;
}*/

.container-image-text-inline + .container-text-slider-partner{
    display:inline-block;
    width:100%;
    margin-top:14vh;
}