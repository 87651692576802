.text-title-01{
    display:block;
    @include base-text(4rem, 400, 1.4em);
    
    &.fw-less{
        font-weight:200;
    }
    
    @media screen and (max-width: 1200px){
        font-size:3.7rem;
    }
    
    @media screen and (max-width: 1050px){
        font-size:3.4rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:3.1rem; 
   }
    
    @media screen and (max-width: 650px){
        font-size:2.8rem;
    }
    
    @media screen and (max-width: 450px){
        font-size:2.5rem;
    }
}

.text-title-02{
    display:block;
    @include base-text(5.4rem, 300, 1.1em);
    
    @media screen and (max-width: 1360px){
        font-size:5rem;
    }
    
    @media screen and (max-width: 1200px){
        font-size:4.4rem;
    }
    
    @media screen and (max-width: 1050px){
        font-size:4.1rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:3.8rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:3.5rem;
    }
    
    @media screen and (max-width: 450px){
        font-size:3.2rem;
    }
}

.text-title-03{
    display:block;
    @include base-text(4rem, 300, 1.2em);
    
    @media screen and (max-width: 1200px){
        font-size:3.7rem;
    }
    
    @media screen and (max-width: 1050px){
        font-size:3.4rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:3.1rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:2.8rem;
    }
    
    @media screen and (max-width: 450px){
        font-size:2.5rem;
    }
}

.text-title-04{
    display:block;
    @include base-text(3.3rem, 300, 1.6em);
    
    &.fw-less{
        font-weight:200;
    }
    
    &.lh-less{
        line-height:1.4em;
    }
    
    @media screen and (max-width: 1480px){
        font-size:2.8rem;
    }
    
    @media screen and (max-width: 1300px){
        font-size:2.5rem;
    }
}

.text-title-05{
    display:block;
    @include base-text(8rem, 600, 1.1em, 1px);
    
    &.fw-less{
        font-weight:400;
    }
    
    &.smaller{
        font-size:7.2rem;
    }
    
    @media screen and (max-width: 1200px){
        font-size:7.2rem;
        
        &.smaller{
            font-size:6.8rem;
        }
    }
    
    @media screen and (max-width: 1050px){
        font-size:6.6rem;
        
        &.smaller{
            font-size:6.2rem;
        }
    }
    
    @media screen and (max-width: 850px){
        font-size:5.6rem;
        
        &.smaller{
            font-size:5.2rem;
        }
    }
    
    @media screen and (max-width: 650px){
        font-size:4.6rem;
        
        &.smaller{
            font-size:4.2rem;
        }
    }
    
    @media screen and (max-width: 450px){
        font-size:4rem;
        
        &.smaller{
            font-size:3.6rem;
        }
    }
}

.text-title{
    @extend .text-title-01;
}


.text-medium-title-01{
    display:block;
    @include base-text(2rem, 500, 1.5em, 2px, uppercase);
    font-family:$second-font;
    
    &.ls-more{
        letter-spacing:4px;
    }
    
    &.fw-more{
        font-weight:600;
    }
    
    @media screen and (max-width: 1200px){
        font-size:1.8rem;
    }
    
    @media screen and (max-width: 1050px){
        letter-spacing:1px;
        
        &.ls-more{
            letter-spacing:3px;
        }
    }
    
    @media screen and (max-width: 850px){
        font-size:1.7rem;
        
        &.ls-more{
            letter-spacing:2px;
        }
    }
    
    @media screen and (max-width: 650px){
        font-size:1.6rem;
        letter-spacing:0;
        
        &.ls-more{
            letter-spacing:1px;
        }
    }
    
    @media screen and (max-width: 450px){
        font-size:1.4rem;
    }
}

.text-medium-title-02{
    display:block;
    @include base-text(2.4rem, 600, 1.4em, 3px, uppercase);
}

.text-medium-title{
    @extend .text-medium-title-01;
}


.text-small-01{
    display:block;
    @include base-text(1.2rem, 500, 1.4em, 3px, uppercase);
    
    @media screen and (max-width: 650px){
        font-size:1.1rem;
    }
}

.text-small-02{
    display:block;
    @include base-text(1.4rem, 500, 1.8em, 4px, uppercase);
    
    &.smaller{
        font-size:1.2rem;
    }
    
    &.fw-more{
        font-weight:600;
    }
    
    @media screen and (max-width: 1200px){
        font-size:1.3rem;
        letter-spacing:3px;
    }
    
    @media screen and (max-width: 1050px){
        letter-spacing:2px;
    }
    
    @media screen and (max-width: 850px){
        font-size:1.2rem;
    }
    
    @media screen and (max-width: 650px){
        &.smaller{
            font-size:1.1rem;
        }
    }
}

.text-small-03{
    display:block;
    @include base-text(1.6rem, 600, 1.2em, 3px, uppercase);
    
    @media screen and (max-width: 1600px){
        font-size:1.5rem;
        letter-spacing:2px;
    }
    
    @media screen and (max-width: 1350px){
        font-size:1.4rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:1.3rem;
    }
}

.text-small-04{
    display:block;
    @include base-text(1.2rem, 600, 1.4em, 0.5px);
    font-family:$second-font;
    
    &.fw-less{
        font-weight:400;
    }
}

.text-small-05{
    display:block;
    @include base-text(1.7rem, 600, 1.4em, 2px, uppercase);
    font-family:$second-font;
}

.text-small{
    @extend .text-small-01;
}


.text-number-follow-01{
    display:block;
    @include base-text(1.2rem, 400, 1.2em, 3px);
    
    strong{
        font-weight:500;
        font-size:2.4rem;
        position:relative;
        top:0.3rem;
    }
}


.text-quote-01{
    display:block;
    @include base-text(1.6rem, 300, 1.8em);
    font-style:italic;
    position:relative;
    
    &:before, &:after{
        position:absolute;
        @include base-text(2.6rem, 400, 1.2em, 1px);
    }
    
    &:before{
        content:"«";
        top:-0.5rem;
        left:-2.5rem;
    }
    
    &:after{
        content:"»";
        bottom:0;
        margin-left:1.25rem;
    }
}


.text-copyright{
    display:block;
    @include base-text(1.6rem, 300, 1.2em, 1px);
}


.text-logo-lubie{
    display:block;
    @include base-text(1.1rem, 300, 1.2em, 2px, uppercase);
    padding-left:4.2rem;
    position:relative;
    color:$second-color;
    @include transition();
    
    svg{
        position:absolute;
        left:0;
        top:50%;
        fill:$second-color;
        @include transformTranslateY(-50%);
        @include transition();
    }
}

&.no-touchevents a.text-logo-lubie:hover{
    color:$second-color-darken2;
    
    svg{
        fill:$second-color-darken2;
    }
}


.text-p-01{
    display:block;
    @include base-text(2.4rem, 200, 1.8em);
    
    strong{
        font-weight:500;
    }
    
    &.lh-less{
        line-height:1.4em;
    }
    
    &.fw-less{
        font-weight:200;
        
        strong{
            font-weight:500;
        }
    }
    
    @media screen and (max-width: 1200px){
        font-size:2.2rem;
    }
    
    @media screen and (max-width: 1050px){
        font-size:2rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:1.8rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:1.7rem;
    }
}

.text-p-02{
    display:block;
    @include base-text(1.5rem, 400, 1.8em);
}

.text-p-03{
    display:block;
    @include base-text(1.8rem, 300, 1.8em);
    
    strong{
        font-weight:500;
    }
    
    &.lh-less{
        line-height:1.4em;
    }
    
    &.fw-less{
        font-weight:200;
    }
    
    @media screen and (max-width: 1400px){
        font-size:1.7rem;
    }
    
    @media screen and (max-width: 1150px){
        font-size:1.6rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:1.5rem;
    }
}

.text-p-04{
    display:block;
    @include base-text(3rem, 300, 1.3em);
    
    strong{
        font-weight:500;
    }
    
    &.bigger{
        font-size:3.4rem;
    }
    
    @media screen and (max-width: 1200px){
        font-size:2.7rem;
    }
    
    @media screen and (max-width: 1050px){
        font-size:2.4rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:2.1rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:1.9rem;
    }
    
    @media screen and (max-width: 450px){
        font-size:1.7rem;
    }
}

.text-p-05{
    display:block;
    @include base-text(2rem, 400, 1.3em);
}

p, .text-p{
    @extend .text-p-01;
}


.text-number-01{
    @include base-text(15.3rem, 700, 1.1em, 3px);
    color:$second-color-darken;
    opacity:0.9;
    font-family:$second-font;
    
    sup{
        display:inline-block;
        position:relative;
        top:-1rem;
        @include base-text(3.3rem, 200, 1.1em);
        margin-right:1.5vw;
        padding-top:1.6rem;
        
        &:before{
            content:" ";
            left:0;
            top:0;
            position:absolute;
            width:90%;
            height:2px;
            background-color:$second-color-darken;
        }
    }
}


.text-icon-pin{
    display:inline-block;
    @include base-text(1.4rem, 500, 1.4em, 2px, uppercase);
    position:relative;
    padding-left:4.4rem;
    color:$second-color-darken;
    
    svg{
        position:absolute;
        width:2.3rem;
        height:3.1rem;
        padding:0.1rem;
        left:0;
        top:50%;
        @include transformTranslateY(-50%);
        fill:$second-color-darken;
    }
}