*{
    box-sizing:border-box;
}

::-moz-selection{
    color:$white;
    background:$second-color;
    
    svg{
        fill:$white;
        background-color:$second-color;
    }
}

::selection{
    color:$white;
    background:$second-color;
    
    svg{
        fill:$white;
        background-color:$second-color;
    }
}

body{
    font-family:$main-font;
    color:$main-color-lessdark;
    fill:$main-color-lessdark;
    font-weight:300;
    line-height:1.1em;
    background:#fff;
    font-size:2rem;
    @include transition(0.15s);
}

img{
    height:auto;
    max-width:100%;
}

a{
    color:inherit;
    text-decoration:none;
    outline:0;
}

button{
    -webkit-appearance:none; appearance:none;
    outline:0;
    border:inherit;
    cursor:pointer;
    background:inherit;
}

svg{
    fill:inherit;
}

/* Reference Link - https://css-tricks.com/custom-scrollbars-in-webkit/ */
::-webkit-scrollbar{width:10px; height:12px;}
::-webkit-scrollbar-button{}
::-webkit-scrollbar-track{}
::-webkit-scrollbar-track-piece{background:$white;}
::-webkit-scrollbar-thumb{background:$black;}
::-webkit-scrollbar-corner{background:$main-color-lessdark;}
::-webkit-resizer{}



/* ***** Global Font Style ***** */
h1, h2, h3, h4, h5, h6{
    font-weight:400;
    line-height:1.2em;
}

h1{
    @extend .text-title-02;
}

h2{
    @extend .text-title-01;
}

h3{
    @extend .text-title-04;
}

h4{
    @extend .text-medium-title-02;
}

h5{
    @extend .text-medium-title-01;
}

h6{
    @extend .text-small-01;
}

strong{
    font-weight:600;
}

p{
    @extend .text-p-01;
}

p a{
    text-decoration:underline;
}


/* ***** Container ***** */
.container-full, .container-big, .container-large, .container-medium, .container-small, .container-tiny{
    margin-left:auto;
    margin-right:auto;
    padding-left:20px;
    padding-right:20px;
    box-sizing:border-box;
    max-width:100%;
    &.shadow{
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
        box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    }
}

.container-big{
    width:$container-big;
}

.container-large{
    width:$container-large;
}

.container-medium{
    width:$container-medium;
}

.container-small{
    width:$container-small;
}

.container-tiny{
    width:$container-tiny;
}

/* ********** Fancybox ********** */
[data-fancybox]{
    cursor:pointer;
}