/* ********** Border ********** */
.small-border{
    display:block;
    width:4rem;
    height:1px;
    background-color:$second-color;
}

.vertical-border{
    border:none;
    display:block;
    width:1px;
    height:9.5rem;
    background-color:rgba(255,255,255,0.5);
}

/* ********** Border Separation ********** */
hr{
    border:none;
    width:100%;
    height:1px;
    margin-top:0;
    margin-bottom:0;
    background-color:$second-color-lighten;
    
    &.bigger{
        height:2px;
    }
}

.border-separation-01{
    background-color:$second-color-darken;
}

.vertical-stroke{
    width:1px;
    height:7.5vh;
    background-color:$second-color-lighten2;
}