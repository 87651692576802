.container-text-center-2{
    text-align:center;
    color:$white;
    padding-top:20vh;
    padding-bottom:5vh;
    color:$main-color;
    position:relative;
    z-index:9;
    
    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.15);
        z-index:-1;
    }
    
    h2{
        @extend .text-title-05, .text-title-05.fw-less;
        margin-bottom:4vh;
    }
    
    hr{
        margin-bottom:3vh;
        background-color:rgba(255,255,255,0.3);
    }
}

.layout-simple .container-text-center-2{
    color:$white;
}