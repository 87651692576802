/* ********** Container Loading Interface ********** */
.container-loading-interface{
    position:fixed;
    left:0;
    bottom:0;
    width:100%;
    height:0%;
    z-index:99999999;
    background-color:$white;
    /*background-color:$white;*/
}

/* ********** Main Logo ********** */
.main-logo{
    width:13.1rem;
    height:11.9rem;
    
    @media screen and (max-width: 1200px){
        width:11.5rem;
        height:10.45rem;
    }
    
    @media screen and (max-width: 767px){
        width:10rem;
        height:9.08rem;
    }
    
    @media screen and (max-width: 550px){
        width:8.5rem;
        height:7.72rem;
    }
}


/* ********** Bubble Top ********** */
.bubble-top{
    position:relative;
    display:block;
    width:17rem;
    height:17rem;
    @include border-radius(50%);
    background-color:$white;
    
    svg{
        position:absolute;
        left:50%;
        top:50%;
        width:77.5%;
        height:77.5%;
        fill:$second-color-lighten2;
        @include transformTranslate(-50%, -50%);
        padding:0.2rem;
    }
}


/* ********** Select Dropdown ********** */
.container-dropdown-custom{
    position:relative;
    width:32rem;
    padding:2rem 3.8rem 5.25rem;
    z-index:9999999;
    /*margin-bottom:4rem;*/
    
    .text-arrow{
        display:block;
        @include base-text(3rem, 400, 1.2em);
        pointer-events:none;
        color:$white;
        position:relative;
        z-index:9999999991;
        padding-right:1.5rem;
    }
    
    .select2-container{
        position:absolute;
        left:0;
        top:0;
        width:100% !important;
        height:100%;
        outline:0;
        /*@include transition();*/
        
        &:before{
            content:" ";
            position:absolute;
            left:3.8rem;
            top:auto;
            top:-1.25rem;
            width:0;
            height:2px;
            background-color:$white;
            z-index:99999999;
            @include transition();
        }
        
        &.select2-container--above{
            .select2-selection__arrow{
                @include transformRotate(180deg);
            }
            
            &.select2-container--open{
                .select2-selection__arrow{
                    @include transformRotate(0deg);
                }
            }
        }
        
        &.select2-container--below{
            &:before{
                top:auto;
                bottom:-1.25rem;
            }
            
            &.select2-container--open{
                .select2-selection__arrow{
                    @include transformRotate(180deg);
                }
            }
            
            .select2-selection__arrow{
                @include transformRotate(0deg);
            }
        }
        
        &.select2-container--open{
            background-color:rgba(2,11,21,0.9);
            
            &:before{
                width:calc(100% - 7.4rem);
            }
        }
        
        .select2-selection{
            color:#7aaed5;
            @include base-text(1.2rem, 600, 1.2em, 4px, uppercase);
            padding-top:6.5rem;
            padding-left:3.5rem;
            border:none;
            background-color:transparent;
            outline:0 !important;
            
            .select2-selection__rendered{
                color:#7aaed5;
                outline:0;
            }
        }
        
        .select2-selection__arrow{
            top:3.3rem;
            right:3rem;
            width:1.9rem;
            height:1rem;
            background-image:url(../images/svg-arrow-select.svg);
            background-repeat:no-repeat;
            @include transition();
            @include transformRotate(180deg);
            
            b{
                display:none;
                @include transition();
            }
        }
    }
    
    @media screen and (max-width: 1300px){
        padding-left:2rem;
        padding-right:3.5rem;
        width:26rem;
        
        .select2-container{
            &:before{
                left:2rem;
            }
            
            &.select2-container--open:before{
                width:calc(100% - 5.1rem);
            }
            
            .select2-selection{
                padding-left:1.75rem;
                letter-spacing:2px;
            }
        }
        
        .text-arrow{
            font-size:2.4rem;
        }
    }
    
    @media screen and (max-width: 550px){
        padding-bottom:3.25rem;
        
        .text-arrow{
            font-size:2rem;
        }
        
        .select2-container .select2-selection{
            padding-top:5.25rem;
        }
    }
}

body.internet-explorer .container-dropdown-custom{
    .text-arrow{
        z-index:9999999;
    }
}

body.internet-explorer .container-dropdown-custom{
    &.dropdown-active{
        .text-arrow{
            z-index:9999999991;
        }
    }
}

/* ********** Select2 Container ********** */
.select2-container{
    z-index:9999999;
    
    .select2-dropdown{
        border:none;
        @include border-radius(0);
        background-color:rgba(2,11,21,0.9);
        min-width:22rem;
        /*max-width:100%;*/

        &.select2-dropdown--below{
            ul.select2-results__options{
                padding-top:5rem;
                padding-bottom:2rem;
            }
        }

        .select2-search--dropdown{
            display:none !important;
        }

        ul.select2-results__options{
            padding-top:2rem;
            padding-bottom:5rem;
            max-height:36rem !important;
        }

        .select2-results__option{
            @include base-text(1.2rem, 700, 1.2em, 3px, uppercase);
            color:$white;
            padding:0.9rem 3.8rem 1rem;
            background-color:transparent;
            @include transition();
        }

        .select2-results__option--highlighted[aria-selected]{
            /*background-color:$second-color-darken;*/
            background-color:transparent;
            color:$second-color;
        }
    }
    
    @media screen and (max-width: 1200px){
        .select2-dropdown{
            .select2-results__option{
                padding-left:2rem;
                padding-right:2rem;
            }
        }
    }
}


/* ********** Content Background Image ********** */
.content-background-image{
    padding-top:6rem;
    padding-bottom:7rem;
    position:relative;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:18rem;
        width:100%;
        height:calc(100% - 18rem);
        background-color:#f0f5fa;
        z-index:-1;
    }

    &.no-offset-bg{
        &:before{
            top:0;
            height:100%;
        }
    }

    @media screen and (max-width: 1024px){
        padding-top:4.5rem;
        padding-bottom:5.5rem;
    }

    @media screen and (max-width: 650px){
        padding-top:3rem;
        padding-bottom:4rem;
    }
}


/* ********** Fancybox Container ********** */
.fancybox-container{
    z-index:999999999;
}