/* ********** Container Text Center ********** */
.container-text-center{
    padding-top:11.5vh;
    padding-bottom:9vh;
    text-align:center;
    color:#0a1e3c;
    font-family:$second-font;

    &.scrollmagic-toggle-class{
        opacity:0;
        @include transitionAppear(1s);

        &.scrollmagic-active{
            opacity:1;
        }
    }
    
    &.variant-text-left{
        text-align:left;
    }

    .title-center{
        @extend .text-title-01, .text-title-01.fw-less;
        color:#0a1e3c;
        margin-bottom:3.6vh;
        display:block;
    }

    .medium-title{
        display:block;
        @include base-text(5.4rem, 500, 1.1em, 1px);
        font-family:$second-font;
        font-style:italic;
        margin-bottom:3rem;
    }

    .headline-text{
        margin-bottom:2.5rem;
    }
    
    h2{
        color:$second-color-darken;
        @extend .text-title-04;
        margin-bottom:2.8vh;
    }
    
    h3{
        color:$second-color-darken;
        @extend .text-title-01, .text-title-01.fw-less;
        margin-bottom:7vh;
    }

    p{
        @include base-text(2.2rem, 200, 1.5em);
        color:$main-color;
        padding-bottom:0.5vh;
        max-width:96rem;
        margin-left:auto;
        margin-right:auto;
    }
    
    hr{
        margin-bottom:4.4vh;
    }

    .container-center-buttons{
        margin-top:2rem;
        margin-left:-1.25rem;

        a{
            margin-bottom:1rem;
            margin-left:1.25rem;
            margin-right:1.25rem;
        }
    }

    .btn-arrow-right{
        margin-top:2rem;
    }

    &.variant-2{
        .title-center{
            color:$main-color;
        }

        .medium-title{
            color:$second-color;
        }
    }

    .scrollmagic-toggle-class{
        @include transition(0.6s);
        opacity:0;

        &.scrollmagic-active{
            opacity:1;
        }
    }

    @media screen and (max-width:1200px){
        p{
            font-size:2.1rem;
        }
    }

    @media screen and (max-width:1024px){
        p{
            font-size:2rem;
        }
    }

    @media screen and (max-width:850px){
        .medium-title{
            font-size:4.6rem;
        }

        p{
            font-size:1.9rem;
        }
    }

    @media screen and (max-width:650px){
        padding-top:4rem;
        padding-bottom:2rem;

        .medium-title{
            font-size:4rem;
        }

        p{
            font-size:1.8rem;
        }
    }

    @media screen and (max-width:450px){
        .medium-title{
            font-size:4rem;
        }

        p{
            font-size:1.7rem;
        }
    }

    @media screen and (max-width:650px){
        padding-top:2rem;
    }
}