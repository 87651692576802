@mixin base-text($fontSize:1.8rem, $fontWeight:400, $lineHeight:1.2em, $letterSpacing:0, $textTransform:none){
    font-size:$fontSize;
    font-weight:$fontWeight;
    line-height:$lineHeight;
    letter-spacing:$letterSpacing;
    text-transform:$textTransform;
}

@mixin color($color){
    color:$color;
}

@mixin border-radius($radius: 3px){
    -webkit-border-radius:$radius;
    -moz-border-radius:$radius;
    border-radius:$radius;

    -moz-background-clip:padding;
    -webkit-background-clip:padding-box;
    background-clip:padding-box;
}

@mixin opacity($opacity: 0.5){
    -webkit-opacity:$opacity;
    -moz-opacity:$opacity;
    opacity:$opacity;
}

@mixin transition($transition: 0.3s, $easing: ease-in-out){
    -webkit-transition:all $transition cubic-bezier(.4,0,.2,1);
    -moz-transition:all $transition cubic-bezier(.4,0,.2,1);
    -ms-transition:all $transition cubic-bezier(.4,0,.2,1);
    -o-transition:all $transition cubic-bezier(.4,0,.2,1);
    transition:all $transition cubic-bezier(.4,0,.2,1);
}

@mixin transitionImportant($transition: 0.3s, $easing: ease-in-out){
    -webkit-transition:all $transition cubic-bezier(.4,0,.2,1) !important;
    -moz-transition:all $transition cubic-bezier(.4,0,.2,1) !important;
    -ms-transition:all $transition cubic-bezier(.4,0,.2,1) !important;
    -o-transition:all $transition cubic-bezier(.4,0,.2,1) !important;
    transition:all $transition cubic-bezier(.4,0,.2,1) !important;
}

@mixin transitionProperty($transition: 0.3s, $property: all){
    -webkit-transition:$property $transition cubic-bezier(.4,0,.2,1);
    -moz-transition:$property $transition cubic-bezier(.4,0,.2,1);
    -ms-transition:$property $transition cubic-bezier(.4,0,.2,1);
    -o-transition:$property $transition cubic-bezier(.4,0,.2,1);
    transition:$property $transition cubic-bezier(.4,0,.2,1);
}

@mixin transitionAppear($transition: 0.3s, $easing: ease-in-out){
    -webkit-transition:all $transition $easing;
    -moz-transition:all $transition $easing;
    -ms-transition:all $transition $easing;
    -o-transition:all $transition $easing;
    transition:all $transition $easing;
}

@mixin transitionDelay($transitionDelay: 0.3s){
    -webkit-transition-delay: $transitionDelay; transition-delay: $transitionDelay;
}

@mixin transformTranslate($x:-50%, $y:-50%){
    -moz-transform:translateX($x) translateY($y); -webkit-transform:translateX($x) translateY($y); -o-transform:translateX($x) translateY($y); -ms-transform:translateX($x) translateY($y); transform:translateX($x) translateY($y);
}

@mixin transformTranslateX($x:-50%){
    -moz-transform:translateX($x); -webkit-transform:translateX($x); -o-transform:translateX($x); -ms-transform:translateX($x); transform:translateX($x);
}

@mixin transformTranslateY($y:-50%){
    -moz-transform:translateY($y); -webkit-transform:translateY($y); -o-transform:translateY($y); -ms-transform:translateY($y); transform:translateY($y);
}

@mixin transformRotate($rotate:180deg){
    -moz-transform: rotate($rotate); -webkit-transform: rotate($rotate); -o-transform: rotate($rotate); -ms-transform: rotate($rotate); transform: rotate($rotate);
}

@mixin transformRotateTranslateX($rotate:180deg, $translateX:50%){
    -moz-transform: rotate($rotate) translateX($translateX); -webkit-transform: rotate($rotate) translateX($translateX); -o-transform: rotate($rotate) translateX($translateX); -ms-transform: rotate($rotate) translateX($translateX); transform: rotate($rotate) translateX($translateX);
}

@mixin transformRotateTranslateY($rotate:180deg, $translateY:50%){
    -moz-transform: rotate($rotate) translateY($translateY); -webkit-transform: rotate($rotate) translateY($translateY); -o-transform: rotate($rotate) translateY($translateY); -ms-transform: rotate($rotate) translateY($translateY); transform: rotate($rotate) translateY($translateY);
}

@mixin transformScale($transformScale:1){
    -moz-transform: scale($transformScale); -webkit-transform: scale($transformScale); -o-transform: scale($transformScale); -ms-transform: scale($transformScale); transform: scale($transformScale);
}

@mixin transformScaleTranslateX($scale:1, $translateX:50%){
    -moz-transform: scale($scale) translateX($translateX); -webkit-transform: scale($scale) translateX($translateX); -o-transform: scale($scale) translateX($translateX); -ms-transform: scale($scale) translateX($translateX); transform: scale($scale) translateX($translateX);
}

@mixin transformScaleTranslateY($scale:1, $translateY:50%){
    -moz-transform: scale($scale) translateY($translateY); -webkit-transform: scale($scale) translateY($translateY); -o-transform: scale($scale) translateY($translateY); -ms-transform: scale($scale) translateY($translateY); transform: scale($scale) translateY($translateY);
}

@mixin transformScaleTranslate($scale:1, $translateX:50%, $translateY:50%){
    -moz-transform: scale($scale) translateX($translateX) translateY($translateY); -webkit-transform: scale($scale) translateX($translateX) translateY($translateY); -o-transform: scale($scale) translateX($translateX) translateY($translateY); -ms-transform: scale($scale) translateX($translateX) translateY($translateY); transform: scale($scale) translateX($translateX) translateY($translateY);
}

@mixin shadow($level: 1){
    @if $level == 1 {box-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);}
    @else if $level == 2 {box-shadow:0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);}
    @else if $level == 3 {box-shadow:0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);}
    @else if $level == 4 {box-shadow:0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);}
    @else if $level == 5 {box-shadow:0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);}
}

@mixin gradientText(){
    background: rgb(145,150,157);
    background: -moz-linear-gradient(left,  rgba(145,150,157,1) 0%, rgba(88,230,207,1) 33%, rgba(221,232,170,1) 66%, rgba(145,150,157,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(145,150,157,1) 0%,rgba(88,230,207,1) 33%,rgba(221,232,170,1) 66%,rgba(145,150,157,1) 100%);
    background: linear-gradient(to right,  rgba(145,150,157,1) 0%,rgba(88,230,207,1) 33%,rgba(221,232,170,1) 66%,rgba(145,150,157,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#91969d', endColorstr='#91969d',GradientType=1 );
    -webkit-background-clip:text; background-clip:text;
    -webkit-text-fill-color:transparent; text-fill-color:transparent;
}

@mixin underscoreText($bgColor:$black){
    content:" ";
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    height:1px;
    background-color:$bgColor;
}

@mixin fullPosition($position:absolute){
    position:$position;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

@mixin contentAbsolute(){
    content:" ";
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

@mixin flexbox(){
    display:-webkit-box; display:-moz-box; display:-ms-flexbox; display:-webkit-flex; display:flex;
}