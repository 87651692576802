/* ********** Lists ********** */

ul.style-default, .style-default ul{
    list-style:none;

    li{
        position: relative;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.7em;
        text-transform: none;
        padding-left: 2.2rem;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0.8rem;
            width:0.6rem;
            height:0.6rem;
            background-color:transparent;
            border:0.1rem solid $second-color-darken;
            @include border-radius(50%);
        }
    }

    @media screen and (max-width: 1200px){
        li{
            font-size:1.9rem;
        }
    }

    @media screen and (max-width: 850px){
        li{
            font-size:1.8rem;
        }
    }

    @media screen and (max-width: 650px){
        li{
            font-size:1.7rem;
        }
    }

    @media screen and (max-width: 450px){
        li{
            font-size:1.6rem;
        }
    }
}

ul.list-social, .list-social ul{
    display:block;
    list-style:none;

    li{
        position:relative;
        display:inline-block;
        margin-right:1.8rem;
        margin-bottom:0.5rem;

        a{
            display:inline-block;
            position:relative;
            width:4rem;
            height:4rem;
            @include border-radius(50%);
            border:1px solid $second-color;
            @include transition();
        }

        &:last-child{
            margin-right:0;
        }
    }

    svg{
        position:absolute;
        left:50%;
        top:50%;
        @include transformTranslate(-50%, -50%);
        fill:$second-color;
        @include transition();
    }

    @media screen and (max-width: 992px){
        li{
            margin-right:1.2rem;

            a{
                width:3.5rem;
                height:3.5rem;
            }
        }
    }
}

&.no-touchevents ul.list-social, &.no-touchevents .list-social ul{
    li a:hover{
        border-color:$main-color-lessdark;

        svg{
            fill:$main-color-lessdark;
        }
    }
}

ul.list-link, .list-link ul{
    li{
        @include base-text(1.5rem, 400, 1.3em);

        a{
            display:inline-block;
            position:relative;
            z-index:9;
            padding-top:0.6rem;
            padding-bottom:0.6rem;
            left:0;
            @include transition();

            &:before, &:after{
                content:" ";
                position:absolute;
                left:-6rem;
                top:0;
                /*@include transformTranslateY(-50%);*/
                width:1px;
                height:0%;
                background-color:$second-color-darken2;
            }

            &:after{
                top:auto;
                bottom:0;
                @include transition();
            }
        }
    }

    @media screen and (max-width: 1340px){
        li a{
            &:before, &:after{
                left:-4.5vw;
            }
        }
    }

    @media screen and (max-width: 992px){
        li a{
            &:before, &:after{
                left:-2.5rem;
            }
        }
    }
}

&.no-touchevents ul.list-link, &.no-touchevents .list-link ul{
    li a:hover{
        color:$second-color-darken2;

        &:before, &:after{
            height:100%;
        }

        &:before{
            @include transition();
        }

        &:after{
            @include transition(0s);
            @include transitionDelay();
        }
    }
}

ul.list-icon, .list-icon ul{
    color:$second-color;

    li{
        position:relative;
        display:block;
        @include base-text(1.4rem, 400, 1.3em, 2px);
        margin-bottom:4.6rem;
        padding-left:5.6rem;

        a{
            @include transition();
        }
    }

    .icon{
        width:4rem;
        height:4rem;
        position:absolute;
        left:0;
        top:50%;
        @include transformTranslateY(-50%);
        @include border-radius(50%);
        border:1px solid $second-color;
        @include transition();

        svg{
            fill:$second-color;
            position:absolute;
            left:50%;
            top:50%;
            @include transformTranslate(-50%, -50%);
            @include transition();
        }
    }
}

&.no-touchevents ul.list-icon, &.no-touchevents .list-icon ul{
    li a:hover{
        color:$main-color;

        .icon{
            border-color:$main-color;

            svg{
                fill:$main-color;
            }
        }
    }
}


/*ul:not(.nav), ul:not(.list-star), ul:not(.langbox), ul:not(.social-network){*/
/*ul.list-dot, .list-dot li{
    list-style-type:none;

    li{
        @include base-text(1.5rem, 400, 1.2em);
        margin-bottom:1.2rem;
        position:relative;
        padding-left:1rem;
        color:$main-color;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0.8rem;
            width:2px;
            height:2px;
            @include border-radius(50%);
            background-color:$main-color;
        }
    }

    @media screen and (max-width: 850px){
        li{
            font-size:1.4rem;
        }
    }
}

ul.list-star{
    li{
        display:inline-block;
        margin-left:-0.1rem;
        margin-right:-0.1rem;
    }

    svg{
        width:2.4rem;
        height:2.4rem;
        fill:$second-color;
    }

    &.small{
        svg{
            width:0.7rem;
            height:0.7rem;
            fill:$white;
        }
    }
}*/


ul.list-circle, .list-circle ul{
    @extend .style-default;
    
    li{
        color:$second-color-lighten;
        position:relative;
        margin-bottom:1.7rem;
        @include base-text(2rem, 300, 1.3em, 0.5px);
        padding-left:2.2rem;

    }
    
    @media screen and (max-width: 1200px){
        li{
            font-size:1.9rem;
        }
    }

    @media screen and (max-width: 850px){
        li{
            font-size:1.8rem;
        }
    }

    @media screen and (max-width: 650px){
        li{
            font-size:1.7rem;
        }
    }

    @media screen and (max-width: 450px){
        li{
            font-size:1.6rem;
        }
    }
}

ul.list-circle-smaller, .list-circle-smaller ul{
    @extend ul.list-circle;

    li{
        font-size:1.7rem;
        line-height:1.6em;
        margin-bottom:2.6rem;
    }
}


ul.list-arrow-right, .list-arrow-right ul{
    li{
        @include base-text(3rem, 300, 1.2em, 1px);
        display:inline-block;
        position:relative;
        margin-bottom:1.75rem;

        a{
            position:relative;
            padding:0.8rem 6.8rem 0.8rem 0;

            &:before, &:after{
                content:" ";
                position:absolute;
                left:0;
                bottom:0;
                width:0;
                height:2px;
                background-color:$white;
            }

            &:after{
                left:auto;
                right:6.8rem;
                @include transition();
            }
        }

        svg{
            position:absolute;
            right:2.8rem;
            top:50%;
            @include transformTranslateY(-50%);
            width:2.5rem;
            height:1.9rem;
        }
    }

    @media screen and (max-width: 1050px){
        li{
            font-size:2.6rem;
        }
    }

    @media screen and (max-width: 850px){
        li{
            font-size:2.2rem;

            svg{
                width:2.2rem;
                height:1.67rem;
                /* 2.5 / 1.9 */
                /* xxxx / yyyy */
            }
        }
    }

    @media screen and (max-width: 650px){
        li{
            font-size:1.8rem;
        }
    }

    @media screen and (max-width: 450px){
        li{
            font-size:1.5rem;

            svg{
                width:1.9rem;
                height:1.44rem;
            }
        }
    }
}

&.no-touchevents ul.list-arrow-right, &.no-touchevents .list-arrow-right ul{
    li a:hover{
        &:before, &:after{
            width:calc(100% - 6.8rem);
        }

        &:before{
            @include transition();
            /*width:calc(100% - 6.8rem);*/
        }

        &:after{
            @include transition(0s);
            @include transitionDelay();
        }
    }
}


ul.list-material, .list-material ul{
    @include flexbox();
    flex-wrap:wrap;

    &:after{
        content:" ";
        display:block;
        clear:both;
    }

    li{
        position:relative;
        @include base-text(1.6rem, 400, 1.4em, 0.5px);
        margin-bottom:3.8rem;
        display:inline-block;
        padding:1rem 0 1rem 4.8rem;
        width:calc(25% - 1px);
        cursor:pointer;

        .icon{
            display:block;
            position:absolute;
            width:3.3rem;
            height:3.3rem;
            left:0;
            /*top:50%;*/
            top:2.25rem;
            @include transformTranslateY(-50%);
            @include border-radius(50%);
        }

        .text{
            position:relative;
            padding-bottom:0.8rem;
            display:inline-block;
            margin-right:2vw;

            &:before{
                content:" ";
                position:absolute;
                left:0;
                bottom:0;
                width:0%;
                height:1px;
                opacity:0.7;
                background-color:$white;
                @include transition();
            }
        }

        &.active{
            .text:before{
                width:100%;
                opacity:1;
            }
        }
    }

    @media screen and (max-width: 1400px){
        li{
            font-size:1.4rem;
        }
    }

    @media screen and (max-width: 1024px){
        li{
            width:calc(33.33333% - 1px);
        }
    }
    
    @media screen and (max-width: 767px){
        li{
            margin-bottom:0;
            padding-bottom:0;
            padding-top:1rem;
            
            .icon{
                top:2rem;
            }
        }
    }
    
    @media screen and (max-width: 500px){
        li{
            padding-left:3.6rem;
            
            .icon{
                width:2.5rem;
                height:2.5rem;
            }
            
            .text{
                letter-spacing:0;
                font-size:1.1rem;
            }
        }
    }
}

&.no-touchevents ul.list-material, &.no-touchevents .list-material ul{
    li:hover{
        .text:before{
            width:100%;
        }
    }
}


ul.list-contact, .list-contact ul{
    li{
        display:block;
        @include base-text(3.3rem, 200, 1.2em);
        color:$second-color-darken;
        position:relative;
        padding:3.8rem 2.5vw 2rem 5vw;
        z-index:9;
        cursor:pointer;
        @include transition();

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:-1px;
            width:100%;
            height:calc(100% + 1px);
            background-color:transparent;
            @include transition();
            z-index:-1;
        }

        &:after{
            content:" ";
            position:absolute;
            left:3vw;
            bottom:0;
            width:calc(100% - 5.5vw);
            height:1px;
            background-color:#c4cad0;
            @include transition();
        }

        &:first-child{
            &:before{
                top:0;
            }
        }
        
        svg{
            position:absolute;
            right:3vw;
            top:56%;
            @include transformTranslateY(-50%);
            width:2.5rem;
            height:2.3rem;
            fill:$second-color-darken;
        }

        &.active{
            color:#0a2753;

            &:before{
                background-color:$white;
            }

            &:after{
                background-color:$white;
            }
        }
    }

    @media screen and (max-width: 1600px){
        li{
            font-size:2.8rem;
        }
    }

    @media screen and (max-width: 1400px){
        li{
            font-size:2.5rem;
        }
    }

    @media screen and (max-width: 1200px){
        li{
            font-size:2.2rem;
            padding-left:4vw;

            &:after{
                left:2vw;
            }
            
            svg{
                right:4vw;
            }
        }
    }

    @media screen and (max-width:850px){
        li{
            padding-left:3vw;

            &:after{
                left:1vw;
            }
        }
    }
    
    @media screen and (max-width: 767px){
        li{
            svg{
                right:6vw;
            }
        }
    }

    @media screen and (max-width:650px){
        li{
            padding-left:2vw;

            &:after{
                left:0;
            }
        }
    }
}

&.no-touchevents ul.list-contact, &.no-touchevents .list-contact ul{
    li:not(.active):hover{
        color:#0a2753;

        &:after{
            background-color:$main-color;
        }
    }
}


ul.list-filter, .list-filter ul{
    display:inline-flex;
    flex-wrap:wrap;

    li, .element-list{
        display:inline-block;
        @include base-text(2rem, 300, 1.2em, 0.5px);

        a{
            color:$second-color-darken;
            padding:3.6rem 3.6rem 0.8rem 3.6rem;
            position:relative;
            display:inline-block;
            @include transition();

            &:after{
                content:" ";
                position:absolute;
                right:0;
                top:0;
                width:1px;
                height:100%;
                background-color:$second-color-darken;
                opacity:0.3;
            }

            &:before{
                content:" ";
                position:absolute;
                left:-1px;
                top:0;
                border-left:1px solid $main-color;
                border-right:1px solid $main-color;
                width:calc(100% - 1px);
                height:0%;
                @include transition();
                @include transitionDelay(0.4s);
            }
        }
        
        &:last-child{
            a:after{
                display:none;
            }
        }

        /*a{
            display:inline-block;
            padding:1rem;
            @include transition();
        }*/
    }
    
    @media screen and (max-width: 1400px){
        li, .element-list{
            a{
                padding-left:3vw;
                padding-right:3vw;
            }
        }
    }
    
    @media screen and (max-width: 1200px){
        li, .element-list{
            font-size:1.8rem;
        }
    }
    
    @media screen and (max-width: 992px){
        li, .element-list{
            font-size:1.6rem;
        }
    }
    
    @media screen and (max-width: 650px){
        li, .element-list{
            font-size:1.4rem;
            
            a{
                padding-left:1.5rem;
                padding-right:1.5rem;
            }
        }
    }
}

ul.list-filter, .list-filter ul{
    li a:hover, .element-list{
        color:$main-color;

        a:hover{
            &:before{
                height:100%;
                @include transitionDelay(0s);
            }
        }
    }
}


ul.list-separator, .list-separator ul{
    li{
        position:relative;
        display:inline-block;
        @include base-text(1.1rem, 600, 1.2em, 2.5px, uppercase);
        margin-left:1.2rem;
        margin-right:1.2rem;
        @include transition();

        a{
            padding:0.6rem 1.2rem;
            position:relative;

            &:before{
                content:" ";
                position:absolute;
                left:50%;
                @include transformTranslateX(-50%);
                bottom:0;
                width:0;
                height:1px;
                background-color:$white;
                @include transition();
            }
        }

        &:before{
            content:"/";
            position:absolute;
            right:-1.6rem;
            top:50%;
            @include transformTranslateY(-50%);
        }

        &:first-child{
            padding-left:0;
        }

        &:last-child{
            padding-right:0;

            &:before{
                display:none;
            }
        }
    }

    @media screen and (max-width: 850px){
        li{
            font-size:1rem;
            margin-left:0.8rem;
            margin-right:0.8rem;
        }
    }
}

&.no-touchevents ul.list-separator, &.no-touchevents .list-separator ul{
    li a:hover{
        &:before{
            width:calc(100% - 2rem);
        }
    }
}



/* ********** Material List Icon ********** */
.material-list-icon{
    @include flexbox();
    flex-wrap:wrap;

    .icon{
        display:block;
        padding:2.6rem 2rem 2.2rem;
        border-bottom:1px solid #dfdfdf;

        img{
            max-width:6rem;
        }
    }

    li{
        float:left;
        width:calc(33.3333% - 1px);
        text-align:center;
        margin-bottom:2rem;

        .text-medium-title{
            @extend .text-small-03;
            padding-top:2rem;
            padding-left:1rem;
            padding-right:1rem;
            margin-bottom:0.6rem;
        }

        .text-small-second{
            padding-left:1rem;
            padding-right:1rem;
            @extend .text-small-04, .text-small-04.fw-less;
            color:$second-color-darken;
        }
    }

    @media screen and (max-width: 1350px){
        li{
            width:calc(50% - 1px);
        }
    }

    @media screen and (max-width: 992px){
        li{
            width:calc(33.3333% - 1px);
        }
    }

    @media screen and (max-width: 600px){
        li{
            width:calc(50% - 1px);
        }
    }
}