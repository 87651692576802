.btn{
    color:$white;
    background-color:$second-color;
    padding:2.2rem 5rem;
    display:inline-block;
    border:1px solid $second-color;
    @include base-text(1.3rem, 500, 1.2em, 3px, uppercase);
    @include border-radius(0);
    @include transition();
    background-clip:border-box;
    -webkit-appearance:none; appearance:none;
    text-align:center;
    position:relative;
    z-index:9;
    
    &:before{
        content:" ";
        position:absolute;
        left:-1px;
        top:-1px;
        width:0%;
        height:calc(100% + 2px);
        background-color:$second-color-darken2;
        z-index:-1;
        @include transition(0.4s);
    }
    
    @media screen and (max-width: 1050px){
        padding-left:4.2rem;
        padding-right:4.2rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:1.2rem;
        letter-spacing:2px;
    }
    
    @media screen and (max-width: 650px){
        font-size:1.1rem;
        padding-left:3.6rem;
        padding-right:3.6rem;
        letter-spacing:1px;
    }
    
    @media screen and (max-width: 450px){
        padding-left:2.8rem;
        padding-right:2.8rem;
    }
}

&.no-touchevents{
    .btn:hover{
        &:before{
            width:calc(100% + 2px);
        }
    }
    
    input[type="submit"].btn:hover{
        background-color:$second-color-darken2;
    }
}


.btn-stroke, .btn-border{
    @extend .btn;
    background-color:$white;
    color:$second-color;
}

&.no-touchevents{
    .btn-stroke:hover, .btn-border:hover{
        color:$white;
        background-color:$second-color;
    }
}


.btn-arrow-right{
    color:$second-color;
    position:relative;
    @include base-text(2.6rem, 300, 1.2em);
    padding-right:4rem;
    @include transition();
    display:inline-block;
    
    &:before{
        content:" ";
        position:absolute;
        left:0;
        bottom:-0.75rem;
        width:0;
        height:1px;
        background-color:$second-color-darken2;
        @include transition();
    }

    svg{
        position:absolute;
        top:50%;
        right:0;
        fill:$second-color;
        @include transformTranslateY(-50%);
        padding:0.2rem;
        @include transition();
        width:2.4rem;
        height:1.9rem;
    }
    
    &.arrow-bottom{
        svg{
            top:auto;
            bottom:0.6rem;
            @include transformTranslateY(0);
        }
    }
    
    &.smaller{
        font-size:2rem;
    }
    
    &.white, &.arrow-white{
        color:$white;
        
        &:before{
            background-color:$white;
        }
        
        svg{
            fill:$white;
        }
    }
    
    @media screen and (max-width: 1050px){
        font-size:2.4rem;
        
        &.arrow-bottom svg{
            bottom:0.45rem;
        }
    }
    
    @media screen and (max-width: 850px){
        font-size:2.2rem;
        
        &.arrow-bottom svg{
            bottom:0.3rem;
        }
    }
    
    @media screen and (max-width: 650px){
        font-size:2rem;
        
        svg{
            width:2.2rem;
            height:1.8rem;
        }
        
        &.arrow-bottom svg{
            bottom:0.2rem;
        }
    }
    
    @media screen and (max-width: 450px){
        font-size:1.9rem;
        
        svg{
            width:2.2rem;
            height:1.74rem;
        }
        
        &.arrow-bottom svg{
            bottom:0.15rem;
        }
    }
}

&.no-touchevents .btn-arrow-right:hover{
    color:$second-color-darken2;
    
    &:before{
        width:calc(100% - 4rem);
    }
    
    svg{
        fill:$second-color-darken2;
        right:-0.8rem;
    }
    
    &.arrow-white, &.white{
        color:$white;
        
        svg{    
            fill:$white;
        }
    }
}


.btn-box-arrow-right{
    display:inline-block;
    position:relative;
    color:$second-color;
    @include base-text(1.1rem, 300, 1.2em, 2px, uppercase);
    padding:1.8rem 8rem 1.8rem 2.4rem;
    border:1px solid $second-color-darken;
    @include transition();
    
    &:before{
        content:" ";
        position:absolute;
        top:0;
        right:5.1rem;
        width:1px;
        height:100%;
        background-color:$second-color;
        @include transition();
    }
    
    svg{
        width:0.8rem;
        height:1.5rem;
        position:absolute;
        right:2.1rem;
        top:50%;
        @include transformTranslateY(-50%);
        fill:$second-color;
        @include transition();
    }
    
    &.bigger{
        padding:2.6rem 10rem 2.6rem 4.5rem;
        font-weight:500;
        
        &:before{
            right:6.1rem;
        }
        
        svg{
            right:2.4rem;
        }
    }
    
    &.arrow-top{
        svg{
            right:1.85rem;
            padding:0.1rem;
            width:1.4rem;
            height:1.8rem;
        }
        
        &.bigger{
            svg{
                right:2.3rem;
            }
        }
    }
    
    &.icon-pdf{
        background-color:$second-color-darken;
        color:$white;
        
        &:before{
            right:0;
            width:5.1rem;
            background-color:$white;
        }
        
        svg{
            padding:0.1rem;
            width:1.5rem;
            height:2.1rem;
            right:1.8rem;
            fill:#374457;
        }
    }
}

&.no-touchevents .btn-box-arrow-right:hover{
    background-color:$second-color;
    color:$white;
    
    &:before{
        background-color:$second-color-lighten;
    }
    
    svg{
        fill:$white;
    }
}


.btn-full{
    width:100%;
}



.link-tel{
    display:inline-block;
    @include base-text(3.4rem, 200, 1.2em);
    color:$second-color;
    @include transition();
    
    &.smaller{
        font-size:2.6rem;
    }
    
    &.fw-more{
        font-weight:300;
    }
    
    @media screen and (max-width: 1050px){
        font-size:3.1rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:2.8rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:2.5rem;
    }
    
    @media screen and (max-width: 450px){
        font-size:2.2rem;
    }
}

&.no-touchevents .link-tel:hover{
    color:$second-color-darken2;
}


.arrow-slider{
    width:6.1rem;
    height:6.1rem;
    position:relative;
    display:block;
    cursor:pointer;
    position:relative;
    background-image:none;
    
    &:before{
        content:" ";
        position:absolute;
        right:0;
        top:0;
        width:0%;
        height:100%;
        background-color:$second-color-darken2;
        @include transition(0.4s);
    }
    
    svg{
        position:absolute;
        left:50%;
        top:50%;
        @include transformTranslate(-50%, -50%);
        width:2.2rem;
        height:1.7rem;
        padding:0.1rem;
        fill:$white;
        @include transition();
    }
    
    /* Arrow Left */
    &.arrow-slider-left{
        background-color:$second-color;
    }
    
    /* Arrow Right */
    &.arrow-slider-right{
        background-color:#0a2753;
        
        &:before{
            left:0;
            background-color:$main-color-lighten2;
        }
    }
    
    /* Variant - Arrow White */
    &.arrow-white{
        &:before{
            background-color:$second-color-darken2;
        }
        
        svg{
            fill:#797a7b;
        }
        
        &.arrow-slider-left, &.arrow-slider-right{
            background-color:$white;
        }
    }
    
    /* Dark */
    &.dark{
        background-color:#0a2753;
        
        &:before{
            background-color:$main-color-lighten2;
        }
    }
}

&.no-touchevents .arrow-slider:hover{
    &:before{
        width:100%;
    }
    
    svg{
        fill:$white;
        /*margin-left:-0.3rem;*/
    }
    
    &.arrow-slider-right{
        svg{
            /*margin-left:0.3rem;*/
        }
    }
}


.link-underscore{
    display:inline-block;
    @include base-text(1.2rem, 500, 1.2em, 2px, uppercase);
    color:$second-color-lighten;
    position:relative;
    padding-bottom:0.75rem;
    @include transition();
    
    &:after{
        content:" ";
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        height:1px;
        background-color:$second-color-lighten;
        @include transition();
    }
    
    &.dark{
        color:$main-color;
        
        &:after{
            background-color:$main-color-lighten;
        }
    }
    
    @media screen and (max-width: 1480px){
        letter-spacing:1px;
    }
    
    @media screen and (max-width: 1300px){
        font-size:1.1rem;
    }
    
    @media screen and (max-width: 650px){
        letter-spacing:0;
    }
    
    @media screen and (max-width: 450px){
        font-size:1rem;
    }
}

&.no-touchevents .link-underscore:hover{
    color:$second-color-darken;
    
    &:after{
        background-color:$second-color-darken;
    }
    
    /*&:after{
        width:100%;
    }*/
    
    &.dark{
        color:$second-color;
        
        &:after{
            background-color:$second-color;
        }
    }
}


.btn-icon-chat{
    position:relative;
    @include base-text(1.3rem, 500, 1.1em, 2.5px, uppercase);
    background-color:$second-color-darken;
    color:$white;
    padding:2.2rem 4.2rem 2.2rem 12rem;
    display:inline-block;
    @include transition();
    
    svg{
        position:absolute;
        left:3.8rem;
        top:50%;
        @include transformTranslate(-50%, -50%);
        width:3.3rem;
        height:2.8rem;
        fill:#0a2753;
        padding:0.1rem;
    }
    
    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:7.4rem;
        height:100%;
        background-color:$white;
    }
    
    @media screen and (max-width: 1050px){
        padding-left:10.5rem;
        padding-right:2.7rem;
    }
    
    @media screen and (max-width: 850px){
        font-size:1.2rem;
    }
    
    @media screen and (max-width: 650px){
        font-size:1.1rem;
    }
    
    @media screen and (max-width: 450px){
        padding-left:9.5rem;
        
        &:before{
            width:6.4rem;
        }
        
        svg{
            left:3.3rem;
            width:3rem;
            height:2.54rem;
        }
    }
}

&.no-touchevents .btn-icon-chat:hover{
    background-color:$second-color-darken2;
}


.btn-radius{
    display:inline-block;
    @include base-text(1.2rem, 500, 1.2em, 2px, uppercase);
    color:#767a7e;
    background-color:$white;
    @include border-radius(40px);
    padding:1.8rem 4.4rem;
    border:none;
    font-family:$second-font;
    @include transition();
}

&.no-touchevents .btn-radius:hover{
    background-color:$second-color-darken;
    color:$white;
}


.btn-radius-02{
    @extend .btn-radius;
    background-color:$second-color-darken;
    color:$white;
}

&.no-touchevents .btn-radius-02:hover{
    background-color:$second-color-darken2;
}



.btn-icon-svg-right{
    display:inline-block;
    
    svg{
        width:2.4rem;
        height:1.9rem;
        padding:0.2rem;
        fill:$white;
    }
}



.btn-double-arrow{
    /*width:8.3rem;
    height:8.3rem;*/
    width:5vw;
    height:5vw;
    min-width:6rem;
    min-height:6rem;
    max-width:8.3rem;
    max-height:8.3rem;
    background-color:$white;
    position:relative;
    display:block;
    
    &:before{
        content:" ";
        position:absolute;
        right:0;
        top:0;
        width:0%;
        height:100%;
        background-color:$second-color-darken2;
        @include transition();
    }
    
    svg{
        position:absolute;
        left:50%;
        top:50%;
        /*width:3.2rem;
        height:3.2rem;*/
        width:2vw;
        height:2vw;
        min-width:2.4rem;
        min-height:2.4rem;
        fill:$second-color-darken;
        @include transformTranslate(-50%, -50%);
        @include transition();
    }
}