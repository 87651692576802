@import "common/fonts";
@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/select2/src/scss/core.scss";
// endbower

html{
    font-size:62.5%; /* 1rem = 10px */
}

@import "layouts/mixins-custom";
@import "common/reset";
html{
    @import "common/base";
    @import "common/bootstrap";
    @import "common/helpers";
    @import "common/swiper";
    @import "common/fancybox";
    @import "common/gallery";
    @import "common/contact";
    @import "common/blog";
    @import "common/lists";
    @import "common/border";
    @import "common/buttons";
    @import "layouts/svg";
    @import "layouts/header";
    @import "layouts/footer";
    @import "common/global";
    @import "common/animation";
    @import "common/form";
    @import "common/slider";
    @import "common/text";
    @import "common/custom";

    // Import CSS from plugin to the main css file. To comment if not used.
    @import "../../../../plugins/lubie-custom-blocks/sass/main.scss";
    
@import "blocks/_carousel-large.scss";
@import "blocks/_carousel-small.scss";
@import "blocks/_customblocks.scss";
@import "blocks/_quatre-blocs-colores.scss";
@import "blocks/_text-2-cols.scss";
@import "blocks/_text-3-cols.scss";
@import "blocks/_text-center.scss";
    
@import "/Users/jeremielamoureuxbelair/www/granit-design/web/app/themes/lubie/blocks/2-blocs-texts/assets/styles.scss";
}