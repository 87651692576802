/* ********** Container Image Popup ********** */
.container-image-popup{
    position:relative;
    padding-top:4vh;
    padding-bottom:2.8vh;

    .content-image{
        position:relative;
        display:inline-block;
        width:100%;
        line-height:0;
        z-index:9;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background-color:rgba(0,0,0,0.06);
            @include transition();
            z-index:1;
        }

        svg{
            position:absolute;
            left:50%;
            top:50%;
            @include transformTranslate(-50%, -50%);
            fill:$white;
            z-index:9;
        }
    }

    figure{
        margin-bottom:2rem;

        figcaption{
            @include base-text(1rem, 700, 1.3em, 0.5px, uppercase);
            color:#01235e;
            margin-top:1.8rem;
        }
    }

    img{
        width:100%;
    }

    .row{
        margin-left:-0.8rem;
        margin-right:-0.8rem;

        .col{
            padding-left:0.8rem;
            padding-right:0.8rem;
        }
    }
}

&.no-touchevents .container-image-popup{
    .content-image[data-fancybox]:hover{
        &:before{
            background-color:rgba(0,0,0,0);
        }
    }
}
