/* ********** Swiper Container ********** */
.swiper-container{
    .swiper-button-prev, .swiper-button-next{
        background-image:none;
        top:0;
        left:0;
        margin-top:0;
        @include transformTranslate(0,0);
        display:inline-block;
        counter-reset:count-pagination-slider-test;
    }
    
    .content-pagination{
        .text-pagination{
            @include base-text(1.2rem, 300, 1.2em, 4px);
            color:$white;
            display:inline-block;
        }
        
        .swiper-pagination{
            position:relative;
            right:0.4rem;
            top:0.4rem;
            width:auto;
            display:inline-block;
            color:$white;
            @include base-text(2.6rem, 300, 1.2em, 4px);

            .swiper-pagination-bullet{
                opacity:1;
                background:none;
                display:none;
                width:auto;
                height:auto;
                    
                &.swiper-pagination-bullet-active{
                    display:inline-block;
                }
            }
        }
    }
}


/* ********** Material Slider ********** */
.material-slider{
    .col-right{
        .text-title{
            margin-bottom:4vh;
        }
    }
    
    .right-content{
        position:absolute;
        bottom:0px;
        right:0px;
        z-index:999999;
        
        .arrow-slider{
            position:absolute;
            left:0;
            right:auto;
            top:-2rem;
            @include transformTranslateY(-100%);
            bottom:4.4rem;
        }
        
        .m-swiper-button-next{
            left:6.4rem;
        }
        
        .border-box{
            display:block;
            border:1px solid #bebfbf;
            border-right:none;
            border-bottom:none;
            background-color:rgba(255,255,255,0.15);
            padding:6vh 4vw 3.5vh 4vw;
            max-width:30vw;
            @include transition();
            
            h3{
                @extend .text-medium-title-02;
                font-size:1.6rem !important;
                font-weight:500 !important;
                margin-bottom:3vh;
            }
            
            p{
                @extend .text-p-02;
                font-size:1.6rem !important;
                font-weight:200 !important;
                font-style:initial;
                margin-bottom:3vh;
                
                &:before, &:after{
                    display:none;
                }
            }
            
            .btn-icon-svg-right{
                position:relative;
                left:0;
                @include transition();
            }
        }
    }
    
    @media screen and (max-width: 767px){
        .right-content{
            height:100%;
            
            .arrow-slider{
                top:22vh;
                @include transformTranslateY(0%);
            }
        }
        
        @media screen and (max-height: 700px){
            .right-content{
                .arrow-slider{
                    top:16rem;
                }
            }
        }
    }
}

&.no-touchevents .material-slider{
    .border-box:hover{
        background-color:rgba(255,255,255,0.1);
        
        .btn-icon-svg-right{
            left:1.5rem;
        }
    }
}

/* ********** Swiper Container Realisation ********** */
.swiper-container-realisation{
    
    .swiper-slide{
        z-index:9;
        
        &:before, &:after{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            z-index:-1;
        }
        
        &:before{
            background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%);
            background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 );
        }
        
        &:after{
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
        }
    }
    
    .swiper-button-prev, .swiper-button-next{
        position:absolute;
        left:auto;
        right:31%;
        top:auto;
        bottom:4.4rem;
        width:6.8rem;
        height:6.8rem;
    }
    
    .swiper-button-prev{
        right:calc(31% + 7.1rem);
    }
    
    .content-pagination{
        position:absolute;
        z-index:9;
        bottom:6rem;
        right:calc(31% + 11.5rem);
        @include transformTranslateX(-100%);
    }
    
    @media screen and (max-width: 1100px){
        .swiper-button-prev, .swiper-button-next{
            right:44%;
        }

        .swiper-button-prev{
            right:calc(44% + 7.1rem);
        }
        
        .content-pagination{
            right:calc(44% + 11rem);
        }
    }
    
    @media screen and (max-width: 767px){
        .swiper-slide{
            &:before{
                background:rgba(0,0,0,0.25);
            }
            
            &:after{
                display:none;
            }
        }
        
        .swiper-button-prev{
            right:calc(44% + 5.5rem);
        }
        
        .content-pagination{
            right:calc(44% + 7rem);
        }
    }
}


/* ********** Swiper Container Custom 01 ********** */
.swiper-container-custom-01{
    position:relative;
    z-index:9;
    color:$main-color;
    
    .text-title{
        @extend .text-title-01.fw-less;
        margin-bottom:7vh;
    }
    
    .col-text{
        position:relative;
        z-index:999;
        /*padding-top:30vh;*/
        padding-top:30vh;
        padding-bottom:11vh;
        padding-right:6vw;
        
        &:before{
            content:" ";
            position:absolute;
            left:-20px;
            top:0;
            width:calc(100% + 40px);
            height:100%;
            background-color:$light-color;
            z-index:-1;
            opacity:0;
        }
    }
    
    .content-image{
        position:absolute;
        right:0;
        top:0;
        width:50%;
        height:100%;
    }
    
    .swiper-container{
        position:relative;
        z-index:9;
        height:80vh;
        max-height:80rem;
        
        .swiper-slide{
            &:before{
                content:" ";
                position:absolute;
                left:0;
                top:0;
                width:50%;
                height:100%;
                background-color:$light-color;
                z-index:9;
            }
        }
    }
    
    .arrow-slider{
        position:absolute;
        top:auto;
        bottom:9vh;
        left:50%;
        z-index:9999;
        
        &.swiper-button-prev{
            margin-left:-1px;
            @include transformTranslateX(-100%);
        }
        
        &.swiper-button-next{
            margin-left:1px;
        }
    }
    
    @media screen and (max-width: 992px){
        .swiper-container .swiper-slide:before{
            width:58.3333333333%;
        }
        
        .arrow-slider{
            left:58.3333333333%;
        }
        
        .content-image{
            width:41.6666666666%;
        }
    }
    
    @media screen and (max-width: 992px){
        .swiper-container .swiper-slide:before{
            display:none;
        }
        
        .arrow-slider{
            left:58.3333333333%;
        }
    }
    
    @media screen and (max-width: 767px){
        .col-text{
            padding-top:10vh;
            
            &:before{
                opacity:1;
            }
        }
        
        .swiper-container{
            height:auto;
            max-height:none;
        }
        
        .content-image{
            position:relative;
            left:0;
            width:100%;
            height:calc((100vw * 730) / 720);
            max-height:none;
        }
        
        .arrow-slider{
            left:20px;
            top:auto;
            bottom:calc((100vw * 730) / 720);
            
            &.swiper-button-prev{
                @include transformTranslateY(60%);
            }
            
            &.swiper-button-next{
                @include transformTranslate(100%, 60%);
            }
        }
    }
}


/* ********** Swiper Container Custom 02 ********** */
.swiper-container-custom-02{
    position:relative;
    z-index:9;
    background-color:$light-color;
    color:$main-color;
    
    /*&:before{
        content:" ";
        position:absolute;
        right:8.3333333%;
        top:0;
        width:70.844444%;
        height:calc(100% - 7vh);
        background-color:#e7f0f5;
        z-index:-1;
    }*/
    
    .text-title{
        @extend .text-title-03;
        margin-bottom:6.4vh;
    }
    
    .col-text{
        position:relative;
        z-index:999;
        margin-top:8.5vh;
        margin-bottom:12.5vh;
        padding-top:7vh;
        padding-bottom:11vh;
        padding-right:6vw;
        
        &:before{
            content:" ";
            position:absolute;
            left:-20px;
            top:0;
            width:calc(100% + 20px);
            height:100%;
            z-index:-1;
            background-color:rgba(255,255,255,0.95);
            opacity:0;
        }
    }
    
    .swiper-container{
        position:relative;
        z-index:9;
        height:65vh;
        min-height:60rem;
        max-height:80rem;
        padding-top:5.5vh;
        
        .swiper-slide{
            &:before{
                content:" ";
                position:absolute;
                left:0;
                top:8.5vh;
                width:50%;
                height:calc(100% - 21vh);
                /*background-color:rgba(255,255,255,0.95);*/
                background-color:$white;
                z-index:99;
            }
        }
        
        .content-image{
            position:absolute;
            right:0;
            top:0;
            width:75%;
            height:100%;
            z-index:9;
            
            &:before{
                content:" ";
                position:absolute;
                width:calc(100% - 5.1666666%);
                height:100%;
                left:-5.1666666%;
                top:-5.5vh;
                z-index:-1;
                background-color:#e7f0f5;
            }
            
            .zone-image{
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
            }
        }
    }
    
    .arrow-slider{
        position:absolute;
        top:auto;
        bottom:12.5vh;
        left:calc((100vw - 1300px) / 2);
        z-index:9999;
        @include transformTranslateY(50%);
        
        &.swiper-button-prev{
            margin-left:-1px;
            /*@include transformTranslateX(-100%);*/
        }
        
        &.swiper-button-next{
            margin-left:1px;
            @include transformTranslate(100%, 50%);
        }
    }
    
    @media screen and (max-width: 1300px){
        .arrow-slider{
            left:20px;
        }
    }
    
    @media screen and (max-width: 992px){
        .swiper-container .swiper-slide:before{
            width:58.3333333333%;
        }
    }
    
    @media screen and (max-width: 767px){
        .swiper-container{
            height:auto;
            min-height:none;
            max-height:none;
            
            .swiper-slide:before{
                display:none;
            }
            
            .content-image{
                margin-top:-3rem;
                position:relative;
                width:91.6666666667%;
                height:calc((91.6666666667vw * 670) / 1080);
                margin-left:8.3333333333%;
                
                &:before{
                    width:calc(100% + 4.1666666%);
                    left:calc(-4.1666666% - 10px);
                }
            }
        }
        
        .col-text{
            margin-bottom:0;
            
            &:before{
                opacity:1;
            }
        }
        
        .arrow-slider{
            bottom:calc((91.6666666667vw * 670) / 1080);
            
            &.swiper-button-prev{
                @include transformTranslateY(100%);
            }
            
            &.swiper-button-next{
                @include transformTranslate(100%, 100%);
            }
        }
    }
}


/* ********** Swiper Container Custom 03 ********** */
.swiper-container-custom-03{
    position:relative;
    z-index:9;
    background-color:$light-color;
    color:$main-color;
    padding-top:12.5vh;
    padding-bottom:9.5vh;
    
    .text-title{
        @extend .text-title-01.fw-less;
        margin-bottom:3vh;
        color:$second-color;
    }
    
    p{
        @extend .text-p-04;
        margin-bottom:6.5vh;
    }
    
    .text-p{
        @extend .text-p-03;
        margin-bottom:0.75vh;
    }
    
    .text-small{
        @extend .text-small-02, .text-small-02.smaller;
        margin-bottom:1vh;
        color:$second-color;
    }
    
    .content-img-sceau{
        margin-bottom:5vh;
    }
    
    .link-tel{
        margin-bottom:3.5vh;
    }
    
    .col-text{
        padding-bottom:9.5rem;
        padding-right:3rem;
        margin-top:-3rem;
    }
    
    .swiper-container{
        position:absolute;
        right:0;
        top:12.5vh;
        width:62.5%;
        height:calc(100% - 22vh);
        z-index:999;
    }
    
    .arrow-slider{
        position:absolute;
        left:37.5%;
        top:auto;
        bottom:9.6vh;
        z-index:9999;
        
        &.swiper-button-prev{
            @include transformTranslateX(-100%);
            margin-left:-2px;
        }
    }
    
    @media screen and (max-width: 767px){
        padding-bottom:calc((100vw * 645) / 900);
        
        .col-text{
            padding-bottom:5vh;
        }
        
        .swiper-container{
            position:absolute;
            left:0;
            top:auto;
            bottom:0;
            width:100%;
            height:calc((100vw * 645) / 900);
        }
        
        .arrow-slider{
            left:0;
            bottom:0;
            @include transformTranslateX(100%);
            
            &.swiper-button-prev{
                @include transformTranslateX(0);
            }
        }
    }
}


/* ********** Container Text Slider ********** */
.container-text-slider{
    padding-top:5vh;
    padding-bottom:4.5vh;
    background-color:$light-color;

    .text-medium-title{
        @extend .text-medium-title-01.ls-more;
        color:$second-color-darken;
    }
    
    hr{
        margin-top:0.9rem;
    }
}
