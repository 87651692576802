/* ********** Contact Form ********** */
.contact-form{
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"]{
        border:none;
        background-color:transparent;
        width:100%;
        border-bottom:1px solid #b6c5c2;
        padding-left:25px;
        padding-bottom:10px;
        display:inline-block;
        position:relative;
        color:$main-color-lessdark;
        font-family: 'Work Sans', sans-serif;
        @include base-text();
        @include border-radius(0px);
        @include transition();
    }

    input[type="checkbox"]{
        position:relative;
        top:-3px;
    }

    textarea{
        max-width:100%;
        height:150px;
        max-height:100px;
        outline:0;
        margin-bottom:-2px;
    }

    input[type="radio"]{
        width:0;
        margin:0;
        padding:0;
        opacity:0;
    }

    input[type="radio"] + label{
        background:url('../assets/images/icon-radio.png') no-repeat 0 2.5px;
        padding-left:25px;
        color:#778587;
        font-size:16px;
        font-weight:400;
    }

    input[type="radio"]:checked + label{
        background:url('../assets/images/icon-radio-active.png') no-repeat 0 2.5px;
        color:#1b1d24;
    }

    html.no-touchevents .container-form input[type="submit"]:hover{
        background-color:#0ba78f;
    }

    input{
        outline:0;
    }

    input:focus{
        color:#1b1d24;
    }

    .over-input{
        position:relative;
    }

    .over-input:after{
        content:" ";
        position:absolute;
        left:0;
        bottom:0;
        width:0;
        height:2px;
        background-color:$main-color-lessdark;
        @include transition();
    }

    .over-input.active:after{
        width:100%;
    }

    .over-select{
        padding-left:18px;
    }

    .over-select:before{
        content:" ";
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        height:1px;
        background-color:#b6c5c2;
    }

    .col{
        padding-left:10px;
        padding-right:10px;
        margin-bottom:70px;
        text-align:left;
    }

    input[type="submit"]{
        margin-top:15px;
    }

    select{
        background-color:transparent;
        border:none;
        width:100%;
        outline:0;
        color:#6e7679;
        font-weight:400;
        font-size:16px;
        @include border-radius(0px);
        margin-bottom:17px;
    }

    .input-other{
        position:absolute;
        left:0;
    }

    .input-other + label{
        position:absolute;
        top:9px;
    }

    .col-less-margin .col{
        margin-bottom:20px;
    }


    input:focus::-webkit-input-placeholder, .container-form textarea:focus::-webkit-input-placeholder{
        color:$main-color-lessdark;
    }
    input:focus:-moz-placeholder, .container-form textarea:focus:-moz-placeholder {/* Firefox 18- */
        color:$main-color-lessdark;
    }
    input:focus::-moz-placeholder, .container-form textarea:focus::-moz-placeholder{/* Firefox 19+ */
        color:$main-color-lessdark;
    }
    input:focus:-ms-input-placeholder, .container-form textarea:focus:-ms-input-placeholder{
        color:$main-color-lessdark;
    }

} 

.overflow-scroll{
    overflow-y: scroll;
}

.contact-form-holder{
    h3{
        margin-bottom:4rem;
        padding:0 10px;
        line-height: 1.1em;
    }
}


span.wpcf7-not-valid-tip {
    font-size: 1.2rem;
    position: absolute;
    line-height: 1.2em;
    width: 100%;
    display: inline-block;
    left: 0;
    bottom: -28px;
    color: #dc2c00;
}

div.wpcf7-validation-errors{
    margin:0 0 3rem 0;
    padding: 10px;
    background:#000;
    color:#fff;
    border:none;
}

body.ie9{

    .container-form{
        input[type="text"],
        input[type="number"],
        input[type="tel"],
        nput[type="email"]{
            padding-bottom:28px;
        }
    }

}