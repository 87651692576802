/* ********** Main Footer ********** */
.main-footer{
    background-color:$white;
    color:$second-color;
    fill:$second-color-darken;
    padding-bottom:4rem;
    font-family:$second-font;
    
    .text-small{
        @extend .text-small-02, .smaller;
        letter-spacing:3px;
        margin-bottom:2.4rem;
    }
    
    p{
        @extend .text-p-02;
        margin-bottom:3.4rem;
        color:$gray-01;
    }
    
    .text-copyright{
        display:inline-block;
        margin-right:5vw;
        margin-bottom:1.5rem;
    }
    
    .text-logo-lubie{
        display:inline-block;
        top:-0.1rem;
    }
    
    .btn-box-arrow-right{
        margin-right:7.5vw;
        margin-bottom:1.5rem;
    }
    
    .list-link{
        color:$gray-01;
        padding-bottom:2rem;
    }
    
    .list-social{
        margin-bottom:2rem;
    }
    
    .list-icon{
        margin-bottom:3rem;
    }
    
    hr{
        margin-bottom:4.2rem;
        background-color:#dce9f4;
        
        &.less-margin{
            margin-bottom:2rem;
        }
    }
    
    .inner-border{
        position:relative;
        
        &:before{
            content:" ";
            position:absolute;
            left:-6rem;
            width:1px;
            height:100%;
            background-color:#dce9f4;
        }
    }
    
    .col{
        padding-top:5.5vh;
    }
    
    .col-left{
        padding-right:20px;
    }
    
    .col-blue{
        position:relative;
        z-index:9;
        padding-bottom:10rem;
        
        &:before{
            content:" ";
            position:absolute;
            z-index:-1;
            left:-7vw;
            top:0;
            width:calc((((1300px * 25) / 100)) + ((100vw - 1300px) / 2) + 7vw - 5px);
            height:100%;
            background-color:#f8fbfd;
        }
    }
    
    .content-logo{
        .list-logo{
            margin-bottom:0.75rem;
            
            li{
                display:inline-block;
                width:24%;
                height:7rem;
                position:relative;
                text-align:center;
                margin-bottom:1.25rem;
                
                img{
                    position:absolute;
                    left:50%;
                    top:50%;
                    max-width:100%;
                    max-height:100%;
                    padding-left:1rem;
                    padding-right:1rem;
                    @include transformTranslate(-50%, -50%);
                }
            }
        }
    }
    
    @media screen and (max-width: 1340px){
        .inner-border:before{
            left:-4.5vw;
        }
        
        .col-blue:before{
            width:calc((((100vw - 40px) * 25) / 100) + 7vw + 17.5px);
        }
    }
    
    @media screen and (max-width: 1050px){
        .text-small{
            letter-spacing:2px;
        }
    }
    
    @media screen and (max-width: 992px){
        .btn-box-arrow-right{
            margin-right:4vw;
        }
        
        .text-copyright{
            margin-right:3vw;
        }
        
        .inner-border:before{
            left:-2.5rem;
        }
        
        .col-blue:before{
            left:-4vw;
            width:calc((((100vw - 40px) * 29.1666666667) / 100) + 4vw + 17.5px);
        }
    }
    
    @media screen and (max-width: 767px){
        .text-small{
            margin-bottom:1.8rem;
        }
        
        .list-social{
            margin-bottom:1rem;
        }
        
        .col-blue{
            padding-bottom:1.5rem;
            
            &:before{
                width:calc(100% + 40px);
                left:-20px;
            }
        }
        
        .col{
            padding-top:8vh;
        }
    }
    
    @media screen and (max-width: 600px){
        .content-logo .list-logo{
            li{
                width:49%;
            }
        }
    }
}