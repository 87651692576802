/* ********** Container Image Text Inline ********** */
.container-image-text-inline{
    position:relative;
    font-family:$second-font;
    
    .headline-text{
        @extend .text-number-01;
        position:absolute;
        left:0;
        top:-9rem;
    }
    
    .text-title{
        @extend .text-title-02;
        font-weight:400;
        margin-bottom:8vh;
        position:absolute;
        left:0;
        top:11rem;
        max-width:46rem;
    }
    
    .text-medium-title{
        
    }
    
    .text-small{
        
    }
    
    .btn-arrow-right{
        margin-bottom:4vh;
    }

    p{
        margin-bottom:7vh;
    }

    blockquote{
        display:block;

        p{
            
        }
    }

    cite{
        margin-bottom:5rem;
        @include base-text(2.2rem, 200, 1.2em, 0);
        font-family:$main-font;
        font-style:normal;
        display:block;
    }
    
    .content-inner{
        position:relative;
        padding-left:6rem;
        padding-right:6rem;
    }

    .row{
        position:relative;
        /*margin-bottom:20vh;*/
        margin-bottom:22rem;
        
        &:first-child{
            figure:before{
                left:5.6rem;
                top:-5.6rem;
                width:100%;
                height:100%;
            }
        }

        .col{
            position:relative;

            &.col-image{
                position:absolute;
                left:8.33333%;
                top:0;
                z-index:-1;
            }

            &.col-text{
                margin-top:16vh;
                background-color:$white;
                /*position:relative;*/
                position:static;
                z-index:99;
                padding-top:7rem;
                padding-left:11rem;
            }
        }

        &.row-reverse{
            
            .text-title{
                top:auto;
                bottom:calc(100% - 17vh);
                margin-bottom:0;
            }
            
            .headline-text{
                left:auto;
                right:4.5vw;
            }
            
            .col{
                &.col-image{
                    left:auto;
                    right:8.33333%;
                }

                &.col-text{
                    padding-left:0;
                    padding-right:11rem;
                }
            }

            .over-pdf{
                padding-left:11rem;

                .icon-pdf{
                    left:0;
                    @include transformTranslateY(-50%);
                }
            }
        }
    }

    figure{
        position:relative;
        z-index:9;
        
        &:before{
            content:" ";
            position:absolute;
            width:calc(100% + 11.2rem);
            height:100%;
            left:-5.6rem;
            top:5.6rem;
            background-color:#f3f8fb;
        }
        
        &:after{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background: -moz-linear-gradient(left, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 55%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(left, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to right, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99ffffff', endColorstr='#00ffffff',GradientType=1 );
        }

        .over-image{
            position:relative;
            height:calc((((1220px / 100) * 75) * 615) / 900);
            overflow:hidden;
        }

        .image-bg{
            display:block;
            position:absolute;
            left:0;
            top:-45px;
            width:100%;
            height:calc(100% + 60px);
        }

        /*figcaption{
            font-family:$second-font;
            @include base-text(1.5rem, 700, 1.2em, 5px, uppercase);
            color:$second-color;
            position:absolute;
            bottom:0.5rem;
            left:-7rem;
            -webkit-writing-mode:vertical-rl; writing-mode:vertical-rl;
            @include transformRotate(180deg);
        }*/
    }

    /*.over-pdf{
        width:100%;
        position:relative;

        .icon-pdf{
            position:absolute;
            left:-23.5%;
            top:50%;
            display:inline-block;
            background-color:$second-color;
            border:1rem solid $white;
            width:9.4rem;
            height:9.4rem;
            @include transformTranslate(-50%, -50%);
            @include border-radius(50%);

            svg{
                position:absolute;
                left:50%;
                top:50%;
                @include transformTranslate(-50%, -50%);
                fill:$white;
                padding:0;
            }
        }
    }*/

    /*.row-reverse figure figcaption{
        left:auto;
        right:-7rem;
    }*/

    .variant-quote-blog{
        padding-top:11rem;

        .col-text{
            text-align:center;
            margin-top:-11rem;

            img{
                max-width:30rem;
                margin-bottom:5rem;
            }
        }

        .bubble-quote{
            margin-left:auto;
            margin-right:auto;
            margin-bottom:3rem;
        }

        cite{
            @include base-text(1.7rem, 300, 1.2em);
            color:$main-color;

            strong{
                margin-bottom:0.25rem;
                display:block;
                @include base-text(2.2rem, 400, 1.2em, 1px);
                color:$second-color;
            }
        }
    }

    &.container-image-text-inline-2{
        figure{
            height:calc((33.333333vw * 630) / 420);
            max-height:70rem;
            margin-top:-10rem;
        }

        p{
            margin-left:0;
            margin-bottom:3rem;
        }
    }
    
    @media screen and (max-width: 1440px){
        .content-inner{
            padding-left:3vw;
            padding-right:3vw;
        }
        
        .row{
            .col.col-text{
                padding-left:8vw;
            }
            
            &.row-reverse{
                .col.col-text{
                    padding-right:8vw;
                }
            }
        }
    }
    
    @media screen and (max-width: 1200px){
        figure{
            .over-image{
                height:calc((((100vw / 100) * 75) * 615) / 900);
            }
        }
    }
    
    @media screen and (max-width: 992px){
        .row{
            margin-bottom:16rem;
        }
        
        .row:last-child{
            margin-bottom:0;
        }
    }
    
    @media screen and (max-width: 767px){
        .text-title{
            position:relative;
            top:0;
            margin-bottom:5vh !important;
        }
        
        .row{
            .col.col-text{
                padding-top:8vh;
                padding-left:5vw;
                padding-right:8vw;
                margin-top:45vw;
            }
            
            .col.col-image{
                left:-3vw;
                
                figure:before{
                    width:calc(100% + 5vw) !important;
                    left:0 !important;
                    top:6vw !important;
                }
            }
            
            &.row-reverse{
                .col.col-text{
                    padding-left:8vw;
                    padding-right:2vw;
                }
            }
        }
        
        figure:after{
            background:transparent;
        }
    }
}

/* ********** Container Image Text Overlap ********** */
.container-image-text-overlap{
    background-color:#f0f5fa;
    padding-top:7rem;
    padding-bottom:11rem;

    .row{
        margin-left:-2rem;
        margin-right:-2rem;

        .col{
            padding-left:2rem;
            padding-right:2rem;
            margin-bottom:4rem;

            &:before{
                left:auto;
                right:0;
            }

            &:nth-child(2n):before{
                left:0;
            }
        }
    }

    figure{
        position:relative;
        line-height:0;

        .over-image{
            position:relative;
            height:calc(((1300px / 2) * 400) / 560);
            overflow:hidden;
        }

        .image-bg{
            display:block;
            position:absolute;
            left:0;
            top:-45px;
            width:100%;
            height:calc(100% + 60px);
        }

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 75%, rgba(255,255,255,0.65) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 75%,rgba(255,255,255,0.65) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 75%,rgba(255,255,255,0.65) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#a6ffffff',GradientType=0 );
            z-index: 9;
        }

        figcaption{
            font-family:$second-font;
            @include base-text(1.5rem, 700, 1.2em, 5px, uppercase);
            color:$second-color;
            position:absolute;
            bottom:0.5rem;
            left:-7rem;
            -webkit-writing-mode:vertical-rl; writing-mode:vertical-rl;
            @include transformRotate(180deg);
        }

        img{
            width:100%;
        }
    }

    .col-right figure figcaption{
        left:auto;
        right:-7rem;
    }

    .big-title{
        @include base-text(7.7rem, 500, 1.1em, 2px);
        font-style:italic;
        font-family:$second-font;
        margin-left:4.4rem;
        margin-bottom:3.2rem;
    }

    p{
        @include base-text(1.8rem, 200, 1.6em);
        margin-bottom:3.8rem;
    }

    .content-text{
        margin-top:-4.8rem;
        position:relative;
        z-index:9;
    }

    &.alternate-vertical {
        background:none;
        .content-text{
            margin-top:2rem;
        }

        figure{
            margin-bottom : 4.4rem;
            &:before{
                position: initial;
                width:0;
                height:0;
                background:none;
            }
            img {
                -webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.1), 0 15px 12px rgba(0,0,0,0.06);
                box-shadow: 0 19px 38px rgba(0,0,0,0.1), 0 15px 12px rgba(0,0,0,0.06);
            }
        }
        .medium-text {
            @include base-text(3.4rem, 400, 1.1em, 1px);
            margin-bottom:3.2rem;
        }
    }

    @media screen and (max-width:1440px){
        .big-title{
            font-size:7rem;
            margin-left:4%;
        }

        .content-text{
            margin-top:-4.4rem;
        }
    }

    @media screen and (max-width:1300px){
        figure{
            .over-image{
                height:calc(((50vw - 20px) * 400) / 560);
            }
        }
    }

    @media screen and (max-width:1200px){
        padding-bottom:10rem;

        .big-title{
            font-size:6.4rem;
        }

        .content-text{
            margin-top:-4.1rem;
        }
    }

    @media screen and (max-width:1024px){
        padding-bottom:9rem;

        .big-title{
            font-size:5.8rem;
            letter-spacing:1px;
            margin-bottom:2.8rem;
        }

        .content-text{
            margin-top:-3.6rem;
        }

        p{
            font-size:1.7rem;
            margin-bottom:3.2rem;
        }
    }

    @media screen and (max-width:850px){
        padding-bottom:8rem;

        .big-title{
            font-size:5rem;
            margin-bottom:2.4rem;
        }

        .content-text{
            margin-top:-3.1rem;
        }

        p{
            font-size:1.6rem;
            margin-bottom:2.8rem;
        }
    }

    @media screen and (max-width:767px){
        .big-title{
            font-size:5.8rem;
        }

        figure{
            .over-image{
                height:calc(((100vw - 20px) * 400) / 560);
            }
        }

        .content-text{
            margin-top:-3.6rem;
        }

        .row .col{
            margin-bottom:6rem;
        }
    }

    @media screen and (max-width:650px){
        padding-top:5rem;

        p{
            font-size:1.5rem;
            margin-bottom:2.2rem;
        }
    }

    @media screen and (max-width: 450px){
        .big-title{
            font-size:4.4rem;
        }

        .content-text{
            margin-top:-2.9rem;
        }
    }
}