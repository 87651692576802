/* ***************************** */
/* ********** HELPERS ********** */
/* ***************************** */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

.bgcontain{
    -webkit-background-size:contain !important;
    -moz-background-size:contain !important;
    -o-background-size:contain !important;
    background-size:contain !important;
}

.bgcover{
    -webkit-background-size:cover !important;
    -moz-background-size:cover !important;
    -o-background-size:cover !important;
    background-size:cover !important;
    background-position:50% 50%;
}

.vertical-text{
    transform: rotate(180deg);
    display: inline-block;
    writing-mode: vertical-rl;
}

.background-position-top{
    background-position:50% 0;
}

.bg-position-bottom{
    background-position:50% 100%;
}

.text-l{
    text-align:left;
}

.text-c{
    text-align:center;
}

.text-r{
    text-align:right;
}

.p-r{
    position:relative;
}

.p-a{
    position:absolute;
}

.float-l{
    float:left;
}

.float-r{
    float:right;
}

.a-no-dec a{
    text-decoration:none !important;
}

.no-transition{
    -webkit-transition: all 0s ease !important; -moz-transition: all 0s ease !important; -ms-transition: all 0s ease !important; -o-transition: all 0s ease !important; transition: all 0s ease !important;
}

.clear-both{
    clear:both;
}

/* ********** No Padding ********** */
.no-padding-tb{
    padding-top:0 !important;
    padding-bottom:0 !important;
}

.no-padding-top{
    padding-top:0 !important;
}

.no-padding-bottom{
    padding-bottom:0 !important;
}

/* FLEXBOX
----------------------------------------------- */
.display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
}

@media (min-width: 480px){
  .xs-up-display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
  }
}

@media (min-width: 768px) {
  .sm-up-display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
  }
  .sm-up-vertical-center{
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 992px) {
    .md-up-ohidden{
        overflow: hidden;
    }
  .md-up-display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
  }
  .md-up-vertical-center{
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
    .lg-up-ohidden{
        overflow: hidden;
    }
    .lg-up-display-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; 
    }
    .lg-up-vertical-center{
        align-items: center;
        justify-content: center;
    }
}