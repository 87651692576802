/* ***** Fonts ***** */
$main-font:'Montserrat', sans-serif;
$second-font:'Work Sans', sans-serif;

/* ***** Color Variables ***** */
$black:#000;
$white:#fff;

/* ***** Brand Variables ***** */
$main-color:#0a1e3c;
$main-color-darken:darken($main-color, 5%);
$main-color-darken2:darken($main-color, 15%);
$main-color-lighten:lighten($main-color, 5%);
$main-color-lighten2:lighten($main-color, 15%);
$main-color-lessdark:lighten($main-color, 5%);

$second-color:#8ab7d9;
$second-color-darken:darken($second-color, 5%);
$second-color-darken2:darken($second-color, 15%);
$second-color-lighten:lighten($second-color, 5%);
$second-color-lighten2:lighten($second-color, 15%);

$accent-color:#de6438;
$accent-color-darken:darken($accent-color, 5%);
$accent-color-darken2:darken($accent-color, 15%);
$accent-color-lighten:lighten($accent-color, 5%);
$accent-color-lighten2:lighten($accent-color, 15%);

$light-color:#f2f6f8;


$light-gray:#e5e5e5;

$gray-01:#787878;

/* ***** Container Variables ****** */
$container-big:1680px;
$container-large:1340px;
$container-large-nopx:1340;
$container-medium:1024px;
$container-small:850px;
$container-tiny:650px;
