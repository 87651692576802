/* ********** Container Banner Homepage ********** */
.container-banner-homepage{
    color:$white;
    position:relative;
    padding-top:28vh;
    padding-bottom:8vh;
    z-index:9;
    min-height:100vh;
    color:$white;
    fill:$white;
    background-position:fixed;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.2);
        z-index:-1;
        /*background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );*/
    }

    .text-title{
        @extend .text-title-01;
        font-weight:300;
        margin-bottom:9.5vh;
    }

    .text-small{
        @extend .text-small-01;
        margin-bottom:3.2vh;
    }

    @media screen and (max-height: 750px){
        padding-top:22rem;
    }

    @media screen and (max-width: 550px){
        padding-top:16rem;
    }
}


/* ********** Container Banner Type ********** */
.container-banner-type{
    position:relative;
    min-height:70vh;
    z-index:9;
    color:$main-color;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:50%;
        height:100%;
        background-color:$light-color;
        z-index:-1;
    }

    .text-title{
        @extend .text-title-01.fw-less;
        margin-bottom:6.5vh;
        margin-left:-0.3rem;
    }

    .text-small{
        @extend .text-small-02;
        color:$second-color-lighten;
        margin-bottom:4.6vh;
    }

    .btn{
        margin-bottom:2.5rem;
    }

    .col-text{
        padding-top:8vh;
        padding-right:6vw;
        position:relative;
        padding-bottom:20vh;

        &:before{
            content:" ";
            position:absolute;
            left:-20px;
            top:0;
            width:calc(100% + 40px);
            height:100%;
            background-color:$light-color;
            z-index:-1;
            opacity:0;
        }
    }

    &.reverse{
        .content-image{
            right:auto;
            left:0;
        }

        .col-text{
            padding-left:6vw;
            padding-right:3rem;
        }
    }

    .content-image{
        position:absolute;
        right:0;
        top:0;
        width:50%;
        height:100%;
    }

    @media screen and (max-width: 992px){
        &:before{
            width:58.3333333333%;
        }

        .content-image{
            width:41.6666666666%;
        }
    }

    @media screen and (max-width: 767px){
        &:before{
            display:none;
        }

        .col-text{
            padding-right:0;
            padding-bottom:0;

            &:before{
                opacity:1;
            }
        }

        .content-image{
            position:relative;
            width:100%;
            height:calc((100vw * 570) / 720);
        }
    }
}

/* ********** Container Bottom Footer ********** */
.container-bottom-footer{
    padding-top:9rem;
    padding-bottom:5.5rem;

    h4{
        @include base-text(3.7rem, 500, 1.2em, 0, uppercase);
        font-family:$second-font;
        font-style:italic;
        margin-bottom:4rem;
        color:$main-color;
    }

    p{
        @include base-text(1.8rem, 300, 1.4em);
        margin-bottom:4.5rem;
        color:$main-color;
    }

    img{
        margin-bottom:4rem;
        max-width:26rem;
        width:100%;
    }

    .row{
        display:-webkit-box; display:-moz-box; display:-ms-flexbox; display:-webkit-flex; display:flex;
        flex-wrap:wrap;
    }

    .col{
        text-align:center;
        margin-bottom:3rem;
        padding:2.5rem 8rem 3.5rem;
        border-right:1px solid #dee8f5;

        &:last-child{
            border-right:none;
        }
    }

    .content-form{
        display:block;

        input{
            height:4.6rem;
        }
    }

    @media screen and (max-width: 1680px){
        .col{
            padding-left:4vw;
            padding-right:4vw;
        }
    }

    @media screen and (max-width: 1440px){
        padding-top:7rem;
        padding-bottom:4rem;
    }

    @media screen and (max-width: 1024px){
        padding-top:5rem;
        padding-bottom:3rem;
    }

    @media screen and (max-width: 992px){
        h4{
            font-size:3rem;
            margin-bottom:2.4rem;
        }

        p{
            font-size:1.7rem;
            margin-bottom:3.2rem;
        }

        img{
            margin-bottom:2.4rem;
        }

        .col{
            padding-left:3vw;
            padding-right:3vw;
        }
    }

    @media screen and (max-width: 850px){
        h4{
            font-size:2.5rem;
        }

        p{
            font-size:1.5rem;
        }

        .content-form input{
            font-size:1.2rem;
        }
    }

    @media screen and (max-width: 767px){
        .col{
            border-right:none;
            padding-top:0.5rem;
            padding-bottom:1.5rem;
        }
    }

    @media screen and (min-width: 767px){
        .col{
            width:calc(33.333333% - 1px);
        }
    }
}


/* ********** Container Double Big Box ********** */
.container-double-big-box{

    .title-box{
        @include base-text(11.3rem, 400, 1em, 1px);
        font-style:italic;
        font-family:$second-font;
        display:block;
        margin-bottom:3.5rem;

        small{
            @include base-text(1.9rem, 400, 1.2em, 6px, uppercase);
            font-style:normal;
            font-family:$main-font;
            display:block;
            margin-bottom:0.5rem;
        }
    }

    p{
        @include base-text(1.8rem, 300, 1.6em);
        margin-bottom:5rem;
    }

    .img-absolute{
        position:absolute;
        left:50%;
        top:50%;
        width:100%;
        height:100%;
        z-index:-2;
        @include transition(0.6s);
        @include transformTranslate(-50%, -50%);
    }

    .vertical-border{
        margin:0 auto 3rem;
    }

    .row{
        display:-webkit-box; display:-moz-box; display:-ms-flexbox; display:-webkit-flex; display:flex;
        flex-wrap:wrap;

        .col{
            text-align:center;
            padding:12rem 6vw 10rem;
            position:relative;
            z-index:9;
            overflow:hidden;

            &.col-left{
                color:$main-color;
                background-color:$main-color;

                &:before{
                    content:" ";
                    position:absolute;
                    z-index:-1;
                    left:2rem;
                    top:2rem;
                    width:calc(100% - 4rem);
                    height:calc(100% - 4rem);
                    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                }
            }

            &.col-right{
                color:$white;
                background-color:$white;
            }
        }
    }

    .scrollmagic-toggle-class{
        &:before{
            opacity:0;
            @include transition(0.6s);
        }

        .img-absolute{
            width:140%;
            height:140%;
            opacity:0;
            @include transition(0.6s);
        }
    }

    .scrollmagic-active{
        &:before{
            opacity:1;
        }

        .img-absolute{
            width:101%;
            height:101%;
            opacity:1;
        }
    }

    &.variant-2{
        .row .col{
            color:$white;

            &.col-left{
                &:before{
                    display:none;
                }
            }
        }
    }

    @media screen and (max-width: 1400px){
        .title-box{
            font-size:10rem;

            small{
                font-size:1.8rem;
            }
        }
    }

    @media screen and (max-width: 1200px){
        .title-box{
            font-size:9rem;

            small{
                font-size:1.7rem;
            }
        }
    }

    @media screen and (max-width: 1024px){
        .row .col{
            padding-top:9rem;
            padding-bottom:7rem;
        }

        .title-box{
            font-size:8rem;

            small{
                font-size:1.6rem;
            }
        }

        p{
            font-size:1.7rem;
        }
    }

    @media screen and (max-width: 850px){
        .title-box{
            font-size:7rem;

            small{
                letter-spacing:4px;
            }
        }
    }

    @media screen and (max-width: 450px){
        .row .col{
            padding-top:7rem;
            padding-bottom:6rem;
        }

        .title-box{
            font-size:6.2rem;

            small{
                font-size:1.5rem;
                letter-spacing:2px;
            }
        }

        p{
            font-size:1.6rem;
        }
    }
}

&.no-touchevents .container-double-big-box{
    a:hover .img-absolute{
        width:110%;
        height:110%;
    }
}

&.touchevents .container-double-big-box{
    @media screen and (min-width: 767px){
        .row .col.col-right{
            width:calc(50% - 1px);
        }
    }
}


/* ********** Container Double Box Toggle Content ********** */
.container-double-box-toggle-content{
    border-bottom:1px solid $light-gray;
    position:relative;

    &:before{
        content:" ";
        position:absolute;
        left:50%;
        width:1px;
        height:100%;
        background-color:$light-gray;
    }

    .row{
        display:-webkit-box; display:-moz-box; display:-ms-flexbox; display:-webkit-flex; display:flex;
        flex-wrap:wrap;

        .col{
            text-align:center;
            padding:1.4rem 4rem 2rem;
            position:relative;
            width:calc(50% - 1px);

            &:before{
                content:" ";
                position:absolute;
                left:50%;
                bottom:0;
                width:0;
                max-width:100%;
                height:5px;
                @include transformTranslateX(-50%);
                background-color:$accent-color;
                @include transition();
            }

            &.col-left{
                padding-top:5rem;
            }

            img{
                width:100%;
                max-width:18rem;
            }
        }
    }

    @media screen and (max-width: 500px){
        .row .col{
            padding-left:3rem;
            padding-right:3rem;

            &.col-left{
                padding-top:11.5%;
            }
        }
    }
}

&.no-touchevents .container-double-box-toggle-content{
    a:hover:before{
        width:27rem;
    }
}


/* ********** Container Banner Box ********** */
.container-banner-box{
    position:relative;
    color:#010f24;
    padding-top:14rem;
    margin-bottom:7rem;

    .img-background{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:calc(100% - 8.5rem);
        min-height:20rem;
    }

    .content-box{
        padding:6rem 0 4rem 7rem;
        background-color:$white;
        -webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.12), 0 15px 12px rgba(0,0,0,0.07); box-shadow: 0 19px 38px rgba(0,0,0,0.12), 0 15px 12px rgba(0,0,0,0.07);
        position:relative;
        width:96%;
        max-width:76rem;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:20%;
            width:0.6rem;
            height:50%;
            background-color:#5a8ccd;
        }
    }

    .content-inner{
        padding-right:6rem;
    }

    .headline-text{
        color:#5a8ccd;
    }

    .big-title{
        @include base-text(4.6rem, 300, 1.2em, 1px);
        margin-bottom:2.2rem;
    }

    .small-text-cta{
        @include base-text(1.4rem, 400, 1.3em);
        display:inline-block;
        margin-left:3rem;
        position:relative;
        top:0.75rem;

        a{
            color:#6594d0;
            font-weight:600;
        }
    }

    .content-amount{
        display:inline-block;
        float:right;
    }

    .content-left{
        margin-top:0.6rem;
        margin-bottom:2rem;
        display:inline-block;
    }

    p{
        @include base-text(1.7rem, 300, 1.6em);
        color:rgba(1,15,36,0.7);
        margin-bottom:3rem;
    }

    .border-separation{
        margin-left:-1.8rem;
        width:calc(100% + 1.8rem);
        margin-bottom:3rem;
    }

    @media screen and (max-width: 1200px){
        padding-top:24rem;
        margin-bottom:5.5rem;

        .img-background{
            height:calc(100% - 22rem);
        }
    }

    @media screen and (max-width: 992px){
        padding-top:30rem;
        margin-bottom:4.5rem;

        .img-background{
            height:calc(100% - 30rem);
        }
    }

    @media screen and (max-width: 850px){
        padding-top:30rem;
        margin-bottom:4.5rem;

        .img-background{
            height:calc(100% - 42rem);
        }

        .big-title{
            font-size:4rem;
        }
    }

    @media screen and (max-width: 650px){
        padding-top:22rem;

        .big-title{
            font-size:3.4rem;
        }

        p{
            font-size:1.6rem;
        }

        .content-box{
            padding-left:4rem;
            padding-top:3rem;
            padding-bottom:2rem;
        }

        .border-separation{
            margin-bottom:2rem;
        }
    }

    @media screen and (max-width: 550px){
        .big-title{
            font-size:2.8rem;
        }

        p{
            font-size:1.5rem;
        }

        .content-inner{
            padding-right:3rem;
        }

        .content-amount{
            float:none;
            display:block;
            margin-bottom:2rem;
        }

        .content-left{
            margin-top:0;
        }
    }
}


/* ********** Content Text Border ********** */
.content-text-border{
    text-align:center;
    margin-top:11vh;
    color:$second-color-lighten;
    position:relative;
    z-index:999;
    font-family:$second-font;

    .text-small{
        @extend .text-small-02;
    }

    .content-border{
        border:1px solid $second-color;
        border-bottom:none;
        padding:8.5vh 5vw;
    }

    @media screen and (max-width: 1200px){
        .content-border{
            border-left:none;
            border-right:none;
            padding-bottom:12rem;
        }
    }
}


/* ********** Container Banner Slider Realisation ********** */
.container-banner-slider-realisation{
    position:relative;
    min-height:100vh;
    color:$white;

    .text-title{
        @extend .text-title-01.fw-less;
        margin-bottom:6vh;
    }

    p{
        @extend .text-quote-01;
        margin-bottom:1.25vh;
        opacity:0.8;
    }

    h4{
        @extend .text-small-04, .text-small-04.fw-less;
        margin-bottom:1vh;
        opacity:0.8;
    }

    .btn-arrow-right{
        margin-top:2vh;
        margin-bottom:3vh;
    }

    .btn{
        padding-top:1.8rem;
        padding-bottom:1.8rem;
    }

    .container-dropdown-custom{
        display:inline-block;

        &:first-child{
            z-index:99;
        }
    }

    .real-shadow{
        width:100%;
        height:100%;
    }

    .col-left{
        max-width:60%;
        position:absolute;
        left:1.5%;
        bottom:1.9rem;
        z-index:9999;
    }

    .col-right{
        position:absolute;
        right:2%;
        height:calc(100% - 4.4rem);
        padding-top:22vh;
        padding-bottom:12rem;
        width:28.16666%;
        z-index:99;

        .btn{
            position:absolute;
            bottom:0;
            width:100%;
            left:0;
        }
    }

    .swiper-container{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }

    &.banner-material{
        .col-left{
            max-width:66%;
        }
    }

    @media screen and (max-width: 1100px){
        .container-dropdown-custom{
            display:block;
        }
        
        .col-right{
            width:40%;
        }

        /*.col-right{
            width:40%;
        }*/
    }

    @media screen and (max-width: 950px){
        .col-left{
            bottom:12rem;
        }
        
        &.banner-material .col-left{
            bottom:1.9rem;
        }
    }
    
    @media screen and (max-width: 850px){
        .swiper-button-prev, .swiper-button-next{
            width:6.6rem;
            height:6.6rem;
        }
    }

    @media screen and (max-width: 767px){
        p, h4{
            display:none;
        }

        .col-left{
            bottom:8rem;
        }
        
        .col-right{
            height:calc(100% - 1.4rem);
        }
        
        .btn{
            padding-top:1.22rem;
            padding-bottom:1.22rem;
            font-size:1.1rem;
        }
        
        .content-pagination{
            bottom:2.75rem;
        }

        &.banner-material{
            .col-right{
                left:20px;
                width:60%;

                .btn{
                    left:auto;
                    right:0;
                    max-width:42%;
                    font-size:0.9rem;
                    padding-left:1rem;
                    padding-right:1rem;
                }
            }
            
            .col-left{
                bottom:1rem;
            }
        }

        .swiper-button-prev, .swiper-button-next, .m-swiper-button-prev, .m-swiper-button-next{
            width:5.2rem !important;
            height:5.2rem !important;
            bottom:1.4rem;
        }

        .right-content{
            width:12.5rem;

            .border-box{
                display:none;
            }

            .m-swiper-button-next{
                left:5.4rem;
            }
        }

        ul.list-material{
            li{
                margin-bottom:0.75rem;
            }
        }

        @media screen and (max-height:400px){
            min-height:110vh;
        }

        @media screen and (max-height:350px){
            min-height:120vh;
        }
        
        &.banner-material{
            .col-left{
                min-width:98%;
            }
            
            @media screen and (max-height:450px){
                min-height:110vh;
            }

            @media screen and (max-height:400px){
                min-height:120vh;
            }

            @media screen and (max-height:350px){
                min-height:130vh;
            }

            @media screen and (max-height:300px){
                min-height:140vh;
            }
        }
    }
    
    @media screen and (max-width: 550px){
        min-height:94vh !important;
        
        .col-right{
            padding-top:13rem !important;
            left:2%;
            width:96%;
        }
        
        .right-content .arrow-slider{
            top:14rem;
        }
        
        .swiper-button-next{
            right:1.4rem;
        }
        
        .swiper-button-prev{
            right:calc(1.4rem + 5.5rem);
        }
        
        .content-pagination{
            right:1.4rem;
            bottom:8rem;
            @include transformTranslateX(0);
        }
        
        .btn{
            max-width:65%;
        }
        
        @media screen and (max-height:500px){
            min-height:110vh !important;
        }
        
        @media screen and (max-height:450px){
            min-height:120vh !important;
        }
        
        @media screen and (max-height:400px){
            min-height:130vh !important;
        }
        
        @media screen and (max-height:350px){
            min-height:140vh !important;
        }
        
        &.banner-material{
            min-height:100vh !important;
            
            @media screen and (max-height:450px){
                min-height:110vh !important;
            }

            @media screen and (max-height:400px){
                min-height:120vh !important;
            }

            @media screen and (max-height:350px){
                min-height:130vh !important;
            }

            @media screen and (max-height:300px){
                min-height:140vh !important;
            }
        }
    }

    @media screen and (min-width: 767px){
        @media screen and (max-height: 750px){
            .text-title{
                margin-bottom:3.5vh;
            }
        }

        @media screen and (max-height: 650px){
            min-height:120vh;

            p, h4{
                display:none;
            }
        }

        @media screen and (max-height: 425px){
            min-height:135vh;
        }
    }
    
    @media screen and (max-height: 700px){
        .col-right{
            padding-top:15rem;
        }
    }
}


/* ********** Container Light Box ********** */
.container-light-box{
    position:relative;
    z-index:9;

    &:before{
        content:" ";
        position:absolute;
        right:0;
        top:0;
        height:100%;
        width:calc((((100vw - 1340px) / 2) + ((1340px * 41.6666666667) / 100)) - 2px);
        background:$light-color;
    }

    .light-title-box{
        padding:11vh 8rem 8vh 8rem;
        color:$second-color;
        position:relative;

        &.offset{
            position:relative;
            margin-top:-19vh;
            background:$white;

            .content-cta-left{
                top:19vh;
            }
        }

        h2{
            @extend .text-title-01, .text-title-01.fw-less;
            color:$second-color-darken;
        }

        h3{
            @extend .text-title-04, .text-title-04.fw-less;
        }

        h4{
            @extend .text-p-01;
            color:$main-color;
        }

        p{
            @extend .text-p-03;
            color:$main-color;
        }

        p, h4{
            margin-bottom:7vh;
        }

        h2, h3, h5{
            margin-bottom:3vh;
        }

        hr{
            width:25%;
            background-color:#d6dbe2;
            margin-bottom:5vh;
            margin-left:0;
            margin-right:0;
            min-width:12rem;
            max-width:60%;
        }

        .content-cta-left{
            display:inline-block;
            position:absolute;
            left:0;
            top:0;
            @include transformTranslate(-100%, -100%);

            .text-vertical-absolute{
                color:#0a1a31;

                &:before{
                    background-color:rgba(10,26,49,0.2);
                }
            }
        }
    }

    .light-box{
        padding:12vh 0 6vh 5.5rem;
        position:relative;
        z-index:9;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:calc(100% + 20px);
            height:100%;
            background-color:$light-color;
            z-index:-1;
            opacity:0;
        }

        p, ul{
            color:$second-color;
        }

        ul{
            margin-bottom:8vh;
        }

        h3{
            @extend .text-medium-title-01, .text-medium-title-01.ls-more;
            margin-bottom:5.5vh;
        }
    }

    @media screen and (max-width: 1440px){
        .light-title-box{
            padding-left:6vw;
            padding-right:6vw;
        }
    }

    @media screen and (max-width: 1340px){
        &:before{
            display:none;
        }

        .light-box:before{
            opacity:1;
        }
    }

    @media screen and (max-width: 1432px){
        padding-left:4.1rem;

        &:before{
            width:calc((((100vw - 1340px) / 2) + ((1340px * 41.6666666667) / 100)) - 2.3rem);
        }
    }

    @media screen and (max-width: 992px){
        padding-left:0;

        .light-title-box{
            padding-left:4vw;
            padding-right:4vw;

            &.offset{
                margin-top:-12vh;

                .content-cta-left{
                    top:0;
                    @include transformTranslateY(0%);
                }
            }
        }

        .light-box{
            padding-left:4vw;
            padding-right:4vw;

            &:before{
                left:-20px;
                width:calc(100% + 40px);
            }
        }
    }

    @media screen and (max-height: 800px){
        .light-title-box{
            padding-top:9rem;
        }
    }
}

&.hiddenscroll .container-light-box{
    &:before{
        width:calc((((100vw - 1340px) / 2) + ((1340px * 41.6666666667) / 100)) - 5px);
    }

    @media screen and (max-width: 1432px){
        &:before{
            width:calc((((100vw - 1340px) / 2) + ((1340px * 41.6666666667) / 100)) - 2.6rem);
        }
    }
}


/* ********** Container Banner Contact ********** */
.container-banner-contact{
    /*padding-top:8.2vh;*/
    padding-top:10vh;
    padding-bottom:22vh;
    color:$white;
    position:relative;
    z-index:9;
    background-position:50% 25%;

    &:before{
        content:" ";
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        z-index:-1;
    }

    &.gradient1{
        color:$main-color;

        .text-small{
            color:$second-color-darken;
        }

        &:before{
            background: -moz-linear-gradient(-45deg, rgba(220,225,230,1) 0%, rgba(220,225,230,0.98) 34%, rgba(220,225,230,0) 70%, rgba(220,225,230,0) 100%);
            background: -webkit-linear-gradient(-45deg, rgba(220,225,230,1) 0%,rgba(220,225,230,0.98) 34%,rgba(220,225,230,0) 70%,rgba(220,225,230,0) 100%);
            background: linear-gradient(135deg, rgba(220,225,230,1) 0%,rgba(220,225,230,0.98) 34%,rgba(220,225,230,0) 70%,rgba(220,225,230,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dce1e6', endColorstr='#00dce1e6',GradientType=1 );
        }
    }

    &.gradient2:before{
        background: -moz-linear-gradient(left, rgba(6,17,34,0.6) 0%, rgba(9,28,57,0.1) 65%, rgba(10,34,70,0.3) 100%);
        background: -webkit-linear-gradient(left, rgba(6,17,34,0.6) 0%,rgba(9,28,57,0.1) 65%,rgba(10,34,70,0.3) 100%);
        background: linear-gradient(to right, rgba(6,17,34,0.6) 0%,rgba(9,28,57,0.1) 65%,rgba(10,34,70,0.3) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99061122', endColorstr='#4d0a2246',GradientType=1 );
    }

    &.less-padding{
        padding-bottom:11vh;
    }

    &.same-padding{
        padding-bottom:8vh;
    }

    .text-small{
        @extend .text-small-02;
        margin-bottom:1.4vh;
    }

    .text-title{
        @extend .text-title-01.fw-less;
        margin-bottom:1.4vh;
        margin-left:-0.3rem;
    }

    p{
        @extend .text-p-03, .text-p-03.fw-less;
        margin-bottom:4vh;
    }

    .col-left{
        position:relative;
        padding-left:2rem;

        &:before{
            content:" ";
            position:absolute;
            left:calc(((-100vw + 1300px) / 2) + 5px);
            top:0;
            width:1.2rem;
            height:100%;
            background-color:$second-color-darken2;
            opacity:0;
        }
    }

    &.stroke-blue-left{
        .col-left:before{
            opacity:1;
        }
    }

    .col-right{
        text-align:right;
    }

    @media screen and (max-width: 1350px){
        .col-left{
            &:before{
                left:calc(((-100vw + 1300px) / 2));
            }
        }
    }

    @media screen and (max-width: 1340px){
        .col-left{
            &:before{
                left:-20px;
            }
        }
    }

    @media screen and (max-width: 767px){
        .col-right{
            text-align:left;
            padding-left:2rem;
        }
    }
}


.container-banner-common{
    text-align:center;
    padding-top:27.5vh;
    padding-bottom:39vh;
    color:$white;
    position:relative;
    z-index:9;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.2);
        z-index:-1;
    }

    .small-text{
        @extend .text-title-03;
        color:$second-color-lighten;
    }

    .title-banner{
        @extend .text-title-05, .text-title-05.fw-less, .text-title-05.smaller;
    }

    &.centered{
        text-align:center;

        span.small-text{
            color:$second-color;
            font-weight:400;
            font-size:3rem;
            margin-bottom:1rem;
            display:inline-block;
        }
    }
}


/* ********** Container Banner Text Left Simple ********** */
.container-banner-text-left-simple{
    padding-top:10vh;
    padding-bottom:30vh;
    text-align:left;
    color:$main-color;

    .text-small{
        @extend .text-small-02;
        color:$second-color;
        margin-bottom:4vh;
    }

    .text-title{
        @extend .text-title-01, .text-title-01.fw-less;
    }
}


/* ********** Container Zone Contact ********** */
.container-zone-contact{
    padding-top:7.5vh;
    padding-bottom:7.5vh;
    position:relative;
    color:$main-color;

    &.icon-top{
        background-color:$light-color;
    }

    .text-title{
        @extend .text-title-01, .text-title-01.fw-less;
        color:$second-color;
        margin-bottom:0.75rem;
    }

    p{
        @extend .text-p-01, .text-p-01.fw-less, .text-p-01.lh-less;
        margin-bottom:2.5vh;
    }

    .content-text{
        margin-bottom:5.5vh;
    }

    .image-bg{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;

        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background-color:rgba(10,26,49,0.93);
        }
    }

    .container-c{
        display:block;
        margin-left:auto;
        margin-right:auto;
        width:calc(100% - 12vw);
        position:relative;
        z-index:99;
        background-color:$white;
        text-align:center;
        padding:7vh 8vw 1vh;
        -webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.1), 0 15px 12px rgba(0,0,0,0.06); box-shadow: 0 19px 38px rgba(0,0,0,0.1), 0 15px 12px rgba(0,0,0,0.06);
    }

    &.icon-top{
        .container-c{
            padding-top:11vh;
            padding-bottom:7.5vh;
        }
    }

    .content-icon-top{
        position:absolute;
        left:50%;
        top:0;
        @include transformTranslate(-50%, -50%);
        width:17rem;
        height:17rem;
        background-color:$white;
        @include border-radius(50%);

        svg{
            width:14rem;
            height:14rem;
            padding:0.5rem;
            position:absolute;
            left:50%;
            top:50%;
            @include transformTranslate(-50%, -50%);
            fill:#bed7ea;
        }
    }

    @media screen and (max-width: 1150px){
        .col-right{
            .row{
                margin-left:-1rem;
                margin-right:-1rem;

                .col{
                    padding-left:1rem;
                    padding-right:1rem;
                }
            }
        }
    }
}

&.no-touchevents .container-zone-contact{
    .image-bg{
        background-attachment:fixed;
    }
}


/* ********** Container Listing Location ********** */
.container-listing-location{
    position:relative;
    z-index:99;
    color:$main-color;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        height:100%;
        background-color:$light-color;
    }

    &:before, .list-contact li:before{
        width:calc((1640px / 4) + ((100vw - 1640px) / 2) - 5px);
    }

    .list-contact li:before{
        left:calc((-100vw + 1640px) / 2 + 5px);
    }

    img{
        width:100%;
        margin-bottom:3.4vh;
    }

    .text-title{
        @extend .text-title-04, .text-title-04.fw-less, .text-title-04.lh-less;
        margin-bottom:3.2vh;
        color:$second-color;
    }

    p{
        @extend .text-p-03, .text-p-03.lh-less;
        margin-bottom:1.8vh;
    }

    .text-small{
        margin-bottom:3.8vh;
    }

    .link-underscore{
        margin-bottom:2.2vh;
    }

    .text-p{
        @extend .text-p-05;
        margin-bottom:2.2vh;
    }

    .col-left{
        padding-bottom:4vh;
        position:relative;
    }

    .col-right{
        margin-top:-11vh;
        padding-left:6rem;
        padding-right:6rem;
        padding-bottom:4.5vh;
        display:none;

        &.active{
          display:block;
        }

        .row{
            margin-left:-1.5rem;
            margin-right:-3.5rem;

            .col{
                padding-left:1.5rem;
                padding-right:1.5rem;
                margin-bottom:6vh;
            }
        }
    }

    @media screen and (max-width: 1690px){
        .list-contact li:before{
            left:calc((-100vw + 1640px) / 2);
        }
    }

    @media screen and (max-width: 1680px){
        &:before, .list-contact li:before{
            width:calc(25vw + 10px);
        }

        .list-contact li:before{
            left:-20px;
        }

        .col-right{
            padding-left:4vw;
            padding-right:4vw;

            .row .col{
                padding-left:1vw;
                padding-right:1vw;
            }
        }
    }

    @media screen and (max-width: 767px){
        overflow:hidden;

        &:before{
            display:none;
        }

        img{
            max-width:30rem;
        }

        .list-contact li:before{
            width:100vw;
        }

        .col-left{
            margin-bottom:4vh;

            &:before{
                content:" ";
                position:absolute;
                left:-20px;
                height:100%;
                width:calc(100% + 40px);
                background-color:$light-color;
            }
        }

        .col-right{
            margin-top:0;
            padding-left:0;
            padding-right:0;

            .row{
                margin-left:0;
                margin-right:0;

                .col{
                    padding-left:0;
                    padding-right:0;
                }
            }
        }
    }
}


/* ********** Container Text Small Image ********** */
.container-text-small-image{
    color:$white;
    position:relative;
    z-index:99;
    font-family:$second-font;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        height:100%;
        width:50%;
        background-color:$main-color;
        z-index:-1;
    }

    p{
        @extend .text-p-04;
    }

    .content-img-sceau{
        margin-bottom:3rem;
    }

    .content-text{
        background-color:$main-color;
        padding-top:11vh;
        padding-bottom:12.5vh;
    }

    .row{
        margin-left:-1.5rem;
        margin-right:-1.5rem;

        .col{
            padding-left:1.5rem;
            padding-right:1.5rem;
        }
    }
}


/* ********** Content Listing Pastille ********** */
.container-listing-pastille{
    text-align:center;
    color:$second-color-darken;
    overflow:hidden;
    position:relative;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        height:28rem;
        background-color:#ebf1f4;
    }

    .content-pastille{
        width:26rem;
        height:26rem;
        border:2.25rem solid $white;
        @include border-radius(50%);
        margin-bottom:3vh;
        display:block;
        margin-left:auto;
        margin-right:auto;
        -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.0), 0 10px 10px rgba(0,0,0,0.0); box-shadow: 0 14px 28px rgba(0,0,0,0.0), 0 10px 10px rgba(0,0,0,0.0);
        @include transformScale(1);
        @include transition();
    }

    .text-p{
        @extend .text-p-01.lh-less;
        margin-bottom:1.75rem;
    }

    .row{
        margin-left:-1vw;
        margin-right:-1vw;
        @include flexbox();
        flex-wrap:wrap;
        justify-content:center;

        &.row-blue{
            position:relative;
            z-index:9;
        }

        .col{
            padding-left:1vw;
            padding-right:1vw;
            padding-bottom:5.5rem;
            position:relative;
            z-index:9;
            width:calc(25% - 1px);

            /*&:before{
                content:" ";
                position:absolute;
                left:0;
                bottom:0;
                width:100%;
                height:calc(100% - 13rem);
                background-color:#ebf1f4;
                opacity:0;
                z-index:-1;
            }

            &:nth-last-child(-n+4){
                &:before{
                    opacity:1;
                }
            }

            &:nth-last-child(4), &:nth-last-child(1){
                &:before{
                    width:calc(100% + ((100vw - 1340px) / 2));
                }
            }

            &:nth-last-child(4){
                &:before{
                    left:calc(((-100vw + 1340px) / 2));
                }
            }*/
        }
    }

    @media screen and (max-width: 1300px){
        &:before{
            height:calc(14rem + 9vw);
        }

        .content-pastille{
            width:18vw;
            height:18vw;
            border:1.75vw solid $white;
        }
    }

    @media screen and (max-width:992px){
        &:before{
            height:calc(13.5rem + 12vw);
        }

        .content-pastille{
            width:22vw;
            height:22vw;
        }

        .row .col{
            width:calc(33.3333333% - 1px);
        }
    }

    @media screen and (max-width:767px){
        &:before{
            height:calc(13.5rem + 15vw);
        }

        .content-pastille{
            width:30vw;
            height:30vw;
        }

        .row{
            margin-left:-3vw;
            margin-right:-3vw;

            .col{
                padding-left:3vw;
                padding-right:3vw;
                width:calc(50% - 1px);
            }
        }
    }
}

&.no-touchevents .container-listing-pastille{
    a:not(.no-hover):hover{
        .content-pastille{
            @include transformScale(1.08);
            -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.04); box-shadow: 0 14px 28px rgba(0,0,0,0.06), 0 10px 10px rgba(0,0,0,0.04);
        }
    }
    a.no-hover:hover{
        cursor: default;
    }
}


/* ********** Container Text Stroke ********** */
.container-text-stroke{
    text-align:center;
    color:$main-color;
    position:relative;
    margin-bottom:7.5rem;

    &:before{
        content:" ";
        position:absolute;
        top:8.5vh;
        left:0;
        width:100%;
        height:1px;
        background-color:#cee0ed;
        z-index:9;
    }

    .text-title{
        @extend .text-title-03;
        margin-bottom:0.75rem;
    }

    .text-medium-title{
        margin-bottom:1.5rem;
        color:$second-color-lighten;
    }

    .content-text{
        padding-top:13vh;
        padding-bottom:7rem;
        background-color:$light-color;
        position:relative;

        .content-bottom{
            position:absolute;
            bottom:0;
            left:50%;
            @include transformTranslate(-50%, 50%);
            width:100%;
        }
    }

    @media screen and (max-width: 450px){
        .content-text{
            padding-bottom:5rem;
        }
    }
}


/* ********** Container Vertical Stroke ********** */
.container-vertical-stroke{
    text-align:center;
    color:$main-color;
    margin-bottom:8vh;

    .text-title{
        @extend .text-title-02;
        margin-bottom:2rem;
    }

    .text-medium-title{
        color:$second-color-lighten;
    }

    hr{
        margin-bottom:2.4rem;
    }
}


/* ********** Container List Image ********** */
.container-list-image{
    .content-image{
        height:calc((((1340px - 40px) / 2) * 400) / 650);
    }

    .row{
        margin-left:-1rem;
        margin-right:-1rem;

        .col{
            padding-left:1rem;
            padding-right:1rem;
            margin-bottom:2.6rem;
        }
    }

    @media screen and (max-width: 1340px){
        .content-image{
            height:calc((((100vw - 40px) / 2) * 400) / 650);
        }
    }

    @media screen and (max-width: 767px){
        .content-image{
            height:calc(((100vw - 40px) * 400) / 650);
        }
    }
}


/* ********** Container Text Slider Partner ********** */
.container-text-slider-partner{
    position:relative;
    z-index:9;
    color:$main-color;
    font-family:$second-font;

    &:before, &:after{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:50%;
        height:100%;
        z-index:-1;
    }

    &:before{
        background-color:#f3f8fb;
    }

    &:after{
        background-color:#e8f1f8;
        left:50%;
    }

    .content-img-sceau{
        position:absolute;
        top:-15vh;
        left:1.25vw;
        @include transformTranslateY(-33%);
    }

    .text-small{
        @extend .text-small-02;
        margin-bottom:6vh;
        color:$second-color;
    }

    p{
        margin-bottom:6vh;
    }

    .col-text{
        position:relative;
        margin-top:15vh;
        padding-left:3.5vw;
        padding-right:4vw;
        border-left:1px solid $second-color-lighten2;
    }

    .col-slider{
        text-align:center;
        padding:11.5vh 6vw 6vw;
        position:relative;

        .content-image{
            height:8vh;
            position:relative;

            img{
                position:absolute;
                left:50%;
                top:50%;
                max-width:90%;
                max-height:100%;
                @include transformTranslate(-50%, -50%);
            }
        }

        .row{
            margin-left:-1rem;
            margin-right:-1rem;

            .col{
                padding-left:1rem;
                padding-right:1rem;
                margin-bottom:4.5vh;
            }
        }
    }

    @media screen and (max-width: 992px){
        background-color:#f3f8fb;

        &:before, &:after{
            display:none;
        }

        .col-slider{
            margin-top:7vh;

            &:after{
                content:" ";
                position:absolute;
                left:-20px;
                top:0;
                width:calc(100% + 40px);
                height:100%;
                background-color:#e8f1f8;
            }
        }
    }

    @media screen and (max-widtH: 767px){
        .col-text{
            margin-bottom:10vh;
        }
    }

    /* Media Height */
    @media screen and (max-height: 800px){
        .content-img-sceau{
            top:-13.5rem;
        }
    }
}


/* ********** Container Listing Carriere ********** */
.container-listing-carriere{
    background-color:$light-color;
    padding-top:2.5vh;
    padding-bottom:11vh;
    color:$main-color;
    font-family:$second-font;

    .text-medium-title{
        @extend .text-medium-title-01, .text-medium-title-01.ls-more;
        margin-bottom:1.5vh;
        color:$second-color-darken;
    }

    p{
        @extend .text-p-03;
        padding-top:1vh;
        margin-bottom:2vh;

        strong{
            font-weight:500;
        }
    }

    ul{
        margin-bottom:3vh;
    }

    .row{
        padding-top:6.5vh;
        padding-bottom:5vh;
        border-bottom:1px solid #c4cad0;

        &:last-child{
            border-bottom:none;
        }

        .col-right{
            padding-top:5.5vh;
            text-align:right;
        }
    }
}


/* ********** Content Img Sceau ********** */
.content-img-sceau{
    position:relative;
    width:14rem;
    height:14rem;
    max-width:100%;

    .img-sceau{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        fill:$second-color-darken;
    }

    .img-sceau-2{
        animation: animationRotate 6s linear 0s infinite;
    }

    @media screen and (max-width: 1150px){
        width:10rem;
        height:10rem;
    }

    @media screen and (max-width: 992px){
        display:none;
    }
}

.img-sceau{
    width:14rem;
    height:14rem;
}

@keyframes animationRotate {
    0%   {@include transformRotate(0deg);}
    100% {@include transformRotate(360deg);}
}



/* ***** Content Back to Top ***** */
.content-back-to-top{
    text-align:center;
    background-color:#ebf1f4;
    padding-top:7vh;
    padding-bottom:8vh;
}


/* ********** Condition Custom Blocks ********** */
.container-text-small-image + .swiper-container-custom-01{
    margin-top:-16vh;

    @media screen and (max-width: 767px){
        margin-top:0;
    }
}

.container-list-image + .container-text-stroke{
    margin-top:-2.6rem;
}


/* ********** Color List ********** */
/*.color-list{
    li{
        float:left;
        position:relative;
        min-height:35px;
        width:25%;
        padding-left:5rem;
        margin-bottom:2rem;
        cursor:pointer;
        img{
            width:3.5rem;
            height:3.5rem;
            position:absolute;
            top:-8px;
            left:0px;
            border-radius: 50%;
        }
        &.active{
          text-decoration:underline;
        }
    }
}*/

/* ********** Loader ********** */
.loader{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:999999999;
    opacity:1;
    display:none;

    h3{
        color:$second-color;
        text-transform:uppercase;
        font-size:2rem;
        margin-top:2rem;

        &:after, &:before{
            content:"-";
        }
    }

    .inner-content{
        @include transformTranslate();
        top:50%;
        left:50%;
        position:absolute;
        text-align:center;
        opacity:0;
    }

    img{
        width:200px;
        height:182px;
    }
    
    .bg-animate-color{
        position:absolute;
        left:0;
        bottom:0;
        width:100%;
        height:0%;
        background-color:$main-color;
        z-index:-1;
    }
}

/* ********** Dual Side ********** */
.dual-side{
    position:relative;

    .text-small{
        @extend .text-small-02, .text-small-02.fw-more;
        border-bottom:1px solid #dfdfdf;
        padding:6vh 0 2rem 0;
    }

    .popup-fancybox{
        display:block;
        width:130%;
        position:relative;
        z-index:99;
        margin-bottom:4rem;
        line-height:0;

        &:before{
            content:" ";
            position:absolute;
            left:4rem;
            top:3.4rem;
            width:calc(100% - 1rem);
            height:100%;
            background-color:$second-color-darken;
            z-index:-1;
        }

        img{
            position:relative;
            left:0;
            top:0;
            -webkit-box-shadow: 10px 19px 38px rgba(0,0,0,0.1), 10px 15px 12px rgba(0,0,0,0.06); box-shadow: 10px 19px 38px rgba(0,0,0,0.1), 10px 15px 12px rgba(0,0,0,0.06);
            @include transition();
        }

        .btn-double-arrow{
            position:absolute;
            right:-3rem;
            top:auto;
            bottom:-3.4rem;
        }
    }

    .col-left{
        padding:12.5vh 4vw 8vh 4vw;
    }

    .blue-box{
        background:$main-color;
        color:#fff;
        padding:12.5vh 8vw 10vh 12vw;
        position:relative;

        .text-title{
            @extend .text-title-02;
            margin-bottom:5vh;
        }

        .text-title-second{
            @extend .text-small-02;
            color:$second-color-darken;
            margin-bottom:1.5vh;
        }

        h4, .text-p{
            @extend .text-p-01;
        }

        h5, .text-small{
            @extend .text-small-05;
            margin-bottom:3.6vh;
            padding-top:2vh;
        }

        p{
            @extend .text-p-03, .text-p-03.fw-less;
        }

        ul{
            margin-bottom:8vh;
        }

        h4, .text-p, p{
            margin-bottom:5vh;
        }

        .btn-arrow-right{
            margin-bottom:1.5rem;
        }
    }

    .content-cta-right{
        position:absolute;
        top:0;
        right:0;
        display:inline-block;
    }

    @media screen and (max-width: 992px){
        .popup-fancybox{
            width:96%;
        }

        .col-left{
            padding-top:8vh;
        }

        .blue-box{
            padding-left:6vw;
            padding-right:6vw;
        }
    }

    @media screen and (max-width: 550px){
        .popup-fancybox{
            width:90%;
        }
    }

    @media screen and (max-height:650px){
        .blue-box{
            padding-top:10rem;
        }
    }
}

&.no-touchevents .dual-side{
    .popup-fancybox:hover{
        img{
            -webkit-box-shadow: 10px 19px 38px rgba(0,0,0,0.14), 10px 15px 12px rgba(0,0,0,0.08); box-shadow: 10px 19px 38px rgba(0,0,0,0.14), 10px 15px 12px rgba(0,0,0,0.08);
        }

        .btn-double-arrow{
            svg{
                fill:$white;
            }

            &:before{
                width:100%;
            }
        }
    }
}


/* ********** Text Vertical Absolute ********** */
.text-vertical-absolute{
    @include base-text(1.2rem, 500, 1.2em, 3px, uppercase);
    color:$second-color-darken;
    display:inline-block;
    position:absolute;
    right:2.4rem;
    top:19.5rem;
    writing-mode:vertical-lr;
    margin-bottom:10vh;
    @include transformRotate(180deg);
    @include transition();
    pointer-events:none;

    &:before{
        content:" ";
        position:absolute;
        left:50%;
        top:100%;
        width:1px;
        height:6.6vh;
        background-color:rgba(255,255,255,0.2);
        @include transformTranslateY(50%);
        @include transition();
    }

    @media screen and (max-width: 992px){
        display:none;
    }
}

body.interet-explorer .text-vertical-absolute{
    display:none;
}

&.no-touchevents a:hover .text-vertical-absolute{
    top:17rem;
    margin-bottom:8vh;
    letter-spacing:2px;

    &:before{
        height:5.6vh;
    }
}



/* ********** Container List Filter ********** */
.container-list-filter{
    padding-top:3vh;
    padding-bottom:2vh;
    text-align:center;
}


/* ********** Container Content Echantillon ********** */
.container-content-echantillon{
    margin-top:-9vh;
    position:relative;
    z-index:99;

    .content-shadow-offset{
        display:inline-block;
        width:100%;
        margin-top:-14vh;
        padding-bottom:6vh;

        .container-c{
            padding:22vh 6vw 7vh;
            -webkit-box-shadow: 0 0 38px rgba(0,0,0,0.1), 0 15px 12px rgba(0,0,0,0.06); box-shadow: 0 0 38px rgba(0,0,0,0.1), 0 15px 12px rgba(0,0,0,0.06);
        }
    }
}

.content-select-color-box{
    position:relative;
    z-index:99999999;

    .select-color-box{
        color:$white;
        background:$main-color;
        padding:6.5vh 8vw 6.5vh 12vw !important;

        h3{
            color:$second-color;
            margin-bottom:3vh;
        }

        .select2-container{
            width:85%;
        }

        .select2-container--default .select2-selection--single{
            background:transparent;
        }

        .select2-container .select2-selection__rendered{
            color:$white;
        }

        @media screen and (max-width: 650px){
            .select2-container{
                width:100%;
            }
        }
    }
}


/* ********** Lubie Layout ********** */
.lubie-layout{
    position:relative;
    z-index:9;

    /*&:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.15);
        z-index:-1;
    }*/
}



/* ********** Distributor map ********** */
#wpsl-wrap{
    background:$light-color;
    margin-bottom:0px;
    padding-bottom:12vh;

    .wpsl-search{
        padding-top:0;
    }
    
    #wpsl-search-wrap{
        padding-top:2rem;
        padding-bottom:2rem;

        #wpsl-category, .wpsl-input, .wpsl-select-wrap{
            margin-bottom:1.5rem;
            margin-top:1.5rem;
        }
        
        .wpsl-input{
            div{
                float:none;
                display:inline-block;
            }
        }

        #wpsl-search-input{
            height:4rem;
        }

        .select2-container{
            width:12rem !important;

            .select2-selection--single{
                height:4rem;

                .select2-selection__arrow{
                    height:4rem;
                }

                .select2-selection__rendered{
                    line-height:4rem;
                    padding-left:2rem;
                    text-align:left;
                }
            }
        }

        form{
            @include flexbox();
            justify-content:center;
            align-items:center;
            flex-wrap:wrap;
            /*@extend .content-form;*/
            @extend .container-full;

            .wpsl-select-wrap{
                select{
                    display:none !important;
                }
            }
        }

        label{
            margin-top:1.05rem;
        }

        .wpsl-search-btn-wrap{
            margin-left:1.5rem;
        }
    }

    .wpsl-search{
        padding-bottom:12px;
    }

    .wpsl-filter{
        background:$white;
        color:#7aaed5;
        text-align:center;

    }

    #wpsl-category{
        clear:none;
    }

    #wpsl-search-btn{
        @extend .btn;
        font-size:1.2rem;
        padding:2rem 3.6rem;
        letter-spacing:1.5px;
        background-image:none;
        text-transform:uppercase !important;
        outline:0;
    }

    #wpsl-gmap{
        border:5rem solid transparent;
    }

    #wpsl-search-wrap{
        width:auto;
        float:none;
        text-align:center;
        display:inline-block;

        label{
            @extend .text-small-02, .text-small-02.smaller;
            font-size:1.1rem !important;
            font-family:$second-font;
            letter-spacing:1px;
            width:auto;
            display:inline-block;
            margin-left:2rem;
        }
    }

    #wpsl-result-list{
        margin-top:9vh;
        margin-left:5vw;
        margin-right:3vw;

        p{
            color:#0a1e3c;
            @extend .text-p-02;
            line-height:1.5em;
            font-family:$second-font;
            text-transform:none;

            strong{
                @extend .text-small-01;
                font-family:$main-font;
                font-size:1.4rem;
                color:$second-color;
                margin-bottom:1.25rem;
            }
        }

        ul{
            li{
                border-bottom:1px solid #dcdfe0;
                padding-bottom:4rem;
                padding-top:5rem;
                padding-right:2rem;

                &:first-child{
                    padding-top:0;
                }
            }
        }
    }

    .wpsl-direction-wrap{
        @extend .text-small-03;

        .wpsl-directions{
            &:before{
                content:' ';
                clear:right;
                display:block;
            }

            margin-top:1rem;
            @extend .link-underscore;
        }
    }
    
    #wpsl-stores{
        height:50rem !important;
    }
    
    @media screen and (max-width: 992px){
        form{
            justify-content:left !important;

            .wpsl-input{
                text-align:left;
            }
        }
    }
    
    @media screen and (max-width: 767px){
        #wpsl-search-wrap{
            .select2-container{
                float:left;
            }
            
            #wpsl-results, .wpsl-search-btn-wrap{
                display:block;
                width:100%;
            }
            
            #wpsl-radius{
                margin-bottom:1rem;
                width:100%;
            }
            
            #wpsl-category, .wpsl-input, .wpsl-select-wrap{
                margin-bottom:1rem;
                margin-top:1rem;
            }
            
            .wpsl-search-btn-wrap{
                margin-top:1rem;
                margin-left:0;
            }
            
            label{
                margin-left:0;
            }
        }
        
        #wpsl-stores{
            height:auto !important;
        }
    }
}

.map-legend-container{
    background-color:$light-color;
    padding-right:3vw;
    width:100%;
    text-align:right;
    position:relative;
    padding-bottom:1.5rem;
    
    .map-legend{
        position:relative;
        top:-14rem;
    }
    
    .text-icon-c{
        @extend .text-small-01;
        color:$second-color-darken;
        position:relative;
        padding-left:4.2rem;
        display:inline-block;
        
        img{
            position:absolute;
            left:0;
            top:50%;
            @include transformTranslateY(-50%);
            width:2.1rem;
            height:2.9rem;
        }
    }
    
    .text-small{
        @extend .text-small-01;
        margin-bottom:1.25rem;
    }
    
    @media screen and (max-width: 767px){
        padding-bottom:10vh;
        
        .map-legend{
            top:0;
        }
    }
}



/* ********** Swiper Container ********** */
.swiper-container{
    &.swiper-container-fade{
        .swiper-slide{
            opacity:0 !important;
            @include transitionImportant(0.8s);

            &.swiper-slide-active{
                opacity:1 !important;
            }
        }
    }
}

.HelpOnClick {
    .HelpOnClick-widget-header{
        height: 30px !important;
    }
    .HelpOnClick-widget-close{
        margin: 10px 0 0 14px !important;
    }
    .HelpOnClick-widget-turn{
        margin: 9px 0 0 0 !important;
    }
    .HelpOnClick-widget-title{
        line-height: 3rem !important;
    }

}



#main{
    line-height:0;
}