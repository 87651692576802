header.main-header{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    z-index:99999;
    padding-top:3rem;
    padding-bottom:3rem;
    color:$white;
    fill:$white;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=0 );
    
    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:0;
        background-color:$white;
        @include transition();
        z-index:-1;
    }
    
    &.variant-header{
        background:transparent;
        color:#6b737f;
        fill:#6b737f;
        position:relative;
        
        &:before{
            height:100%;
        }
        
        .link-logo{
            display:none;
            
            &.link-logo-2{
                display:inline-block;
            }
        }
        
        ul:not(.list-separator){
            /*li a:before{
                background-color:#6b737f;
            }*/
            li{
                a{
                    color:#6b737f;
                    
                    &:before{
                        background-color:$second-color-lighten;
                    }
                }
                
                &.active a{
                    color:$second-color-lighten;
                }
            }
        }
        
        .list-separator{
            color:$second-color-darken;
            
            a:before{
                background-color:$second-color-darken;
            }
        }
        
        .content-hamburger-menu{
            .border{
                background-color:#0a2753;
            }
        }
        
        .btn-radius{
            @extend .btn-radius-02;
        }
        
        .animate-change-header{
            height:4px;
        }
    }
    
    .animate-change-header{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:0;
        background-color:$second-color;
        @include transitionProperty(0.3s, height);
        opacity:0 !important;
    }
    
    .link-logo{
        @include transition();
        @include transformScale(1);
    }
    
    .link-logo-2{
        display:none;
    }
    
    ul:not(.list-separator){
        display:inline-block;
        
        li{
            display:inline-block;
            @include base-text(1.5rem, 400, 1.2em);
            margin-left:0.4rem;
            margin-right:0.4rem;
            @include transition();
            
            a{
                padding:1rem 0.8rem;
                position:relative;
                @include transition();
                
                &:before{
                    content:" ";
                    position:absolute;
                    left:50%;
                    @include transformTranslateX(-50%);
                    bottom:0;
                    width:0;
                    height:1px;
                    background-color:$white;
                    @include transition();
                }
            }
            
            &.active a{
                color:$white;
                
                &:before{
                    width:calc(100% - 1.6rem);
                }
            }
        }
    }
    
    .list-separator{
        margin-bottom:2.4rem;
    }
    
    .col-right{
        float:right;
        text-align:right;
    }
    
    .content-hamburger-menu{
        margin-left:4.5rem;
        margin-right:3.2rem;
        top:1.85rem;
    }
    
    .content-inner{
        @include transition(0.6s);
    }
    
    .container-full{
        padding-left:8vw;
        padding-right:8vw;
    }
    
    @media screen and (max-width: 1680px){
        .container-full{
            padding-left:4vw;
            padding-right:4vw;
        }
    }
    
    @media screen and (max-width: 1400px){
        .container-full{
            padding-left:20px;
            padding-right:20px;
        }
    }
    
    @media screen and (max-width: 1340px){
        ul:not(.list-separator) li{
            font-size:1.4rem;
            margin-left:0;
            margin-right:0;
        }
        
        .btn-radius, .btn-radius-02{
            font-size:1.1rem;
            padding:1.6rem 3.6rem;
        }
        
        .content-hamburger-menu{
            margin-left:3.5rem;
            margin-right:2.8rem;
        }
    }
    
    @media screen and (max-width: 1275px){
        ul:not(.list-separator) li{
            font-size:1.3rem;
        }
    }
    
    @media screen and (max-width: 1200px){
        ul:not(.list-separator) li{
            font-size:1.2rem;
        }
        
        .content-hamburger-menu{
            margin-left:2.8rem;
            margin-right:2.2rem;
        }
        
        .btn-radius, .btn-radius-02{
            padding:1.4rem 3rem;
            font-size:1rem;
        }
    }
    
    @media screen and (max-width: 1100px){
        ul:not(.list-separator) li.hide-first{
            display:none;
        }
        /*ul:not(.list-separator) li{
            font-size:1.1rem;
        }*/
    }
    
    @media screen and (max-width: 1060px){
        ul:not(.list-separator){
            display:none;
        }
        
        .list-separator{
            margin-bottom:1.5rem;
        }
    }
    
    @media screen and (max-width: 767px){
        padding-bottom:1.5rem;
        
        .first-col{
            display:none;
        }
        
        .second-col{
            margin-top:1.6rem;
        }
    }
    
    @media screen and (max-width: 550px){
        padding-top:1rem;
        padding-bottom:1rem;
        
        .btn-radius, .btn-radius-02{
            display:none;
        }
        
        .content-hamburger-menu{
            margin-right:0;
            margin-left:0;
        }
        
        .second-col{
            margin-top:1rem;
        }
    }
}

&.no-touchevents header.main-header{
    &.hide-header{
        .content-inner{
            @include transformTranslateY(-140%);
        }
    }
    
    ul:not(.list-separator){
        li a:hover{
            &:before{
                width:calc(100% - 1.6rem);
            }
        }
    }
    
    &.variant-header{
        ul:not(.list-separator){
            li a:hover{
                color:$second-color-lighten;
            }
        }
    }
}



.content-hamburger-menu{
    position:relative;
    display:inline-block;
    width:3.3rem;
    cursor:pointer;
    
    .border{
        position:relative;
        top:0;
        width:100%;
        height:2px;
        background-color:$white;
        margin-bottom:1rem;
        @include transition();
        
        &:before{
            content:" ";
            position:absolute;
            left:0;
            top:0;
            width:0%;
            height:100%;
            background-color:$second-color;
            @include transition();
        }
        
        /*&.border-2{
            @include transitionDelay(0.15s);
        }
        
        &.border-2{
            @include transitionDelay(0.3s);
        }*/
    }
}

&.no-touchevents .content-hamburger-menu:hover{
    
    .border:before{
        width:100%;
    }
    
    .border-1, .border-3{
        width:80%;
        margin-left:20%;
    }
    
    /*.border-1{
        top:-2px;
    }
    
    .border-3{
        top:2px;
    }*/
}


.container-mask-menu{
    position:fixed;
    right:0;
    top:0;
    width:0%;
    height:100%;
    z-index:999998;
    opacity:1;
    display:none;
    cursor:pointer;
    background: -moz-linear-gradient(left, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0.65) 100%);
    background: -webkit-linear-gradient(left, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0.65) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40ffffff', endColorstr='#a6ffffff',GradientType=1 );
    
    /*&:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-color:rgba(255,255,255,0.1);
        @include transition();
        opacity:1;
    }*/
}

/*&.no-touchevents .container-mask-menu:hover{
    opacity:0.4 !important;
}*/

.container-toggle-hamburger-menu{
    position:fixed;
    top:0;
    right:0;
    width:40%;
    @include transformTranslateX(100%);
    max-width:100%;
    height:100%;
    z-index:999999;
    @include transitionDelay(0.6s);
    padding:10vh 6vw;
    
    &.active{
        @include transformTranslateX(0%);
        @include transitionDelay(0s);
    }
    
    ul{
        li{
            @extend .text-small-04;
            font-size:2.6vh !important;
            font-weight:500 !important;
            margin-bottom:1vh;
            color:$second-color-darken;
            
            a{
                display:inline-block;
                padding-top:0.5vh;
                padding-bottom:0.5vh;
                position:relative;
                z-index:9;
                color:$second-color-darken;
                @include transition();
                
                &:before, &:after{
                    content:" ";
                    position:absolute;
                    left:0;
                    bottom:0;
                    width:0%;
                    height:2px;
                    z-index:-1;
                    background-color:$second-color-lighten2;
                    @include transition(0.4s);
                }
                
                &:before{
                    @include transitionDelay(0.2s);
                }
                
                &:after{
                    background-color:$second-color-darken2;
                }
            }
        }
    }
    
    .nav-mobile{
        display:none;
        
        li:last-child{
            text-transform:capitalize;
        }
    }
    
    .content-close{
        position:absolute;
        top:0;
        right:0;
        /*top:1rem;
        right:1rem;
        width:3.5rem;
        height:3.5rem;
        padding:0.4rem;*/
        cursor:pointer;
        
        .border{
            position:absolute;
            left:50%;
            top:50%;
            width:100%;
            height:2px;
            background-color:#0a2753;
            margin-left:-1.75rem;
            
            &.border-1{
                @include transformRotate(45deg);
            }
            
            &.border-2{
                @include transformRotate(-45deg);
            }
        }
    }
    
    .block-animate{
        position:absolute;
        right:0;
        top:0;
        width:0;
        height:100%;
        background-color:$white;
        z-index:-1;
    }
    
    @media screen and (max-width: 1100px){
        .nav{
            display:none;
            
            &.nav-mobile{
                display:block;
            }
        }
    }
    
    @media screen and (max-width: 650px){
        width:100%;
    }
    
    @media screen and (min-width: 40rem){
        min-width:40rem;
    }
    
    @media screen and (max-height: 600px){
        ul{
            padding-right:5rem;
            
            li{
                font-size:1.6rem !important;
            }
        }
    }
}

&.no-touchevents .container-toggle-hamburger-menu{
    ul li a:hover{
        color:$second-color-darken2;
        
        &:before, &:after{
            width:100%;
        }
        
        &:before{
            @include transitionDelay(0s);
            /*background-color:$second-color-darken;*/
        }
        
        &:after{
            @include transitionDelay(0.15s);
        }
    }
}