.svg-icon-play{
    width:4.2rem;
    height:4.6rem;
}

.svg-icon-phone{
    width:1.5rem;
    height:1.5rem;
}

.svg-icon-chat{
    width:1.9rem;
    height:1.6rem;
}

.svg-icon-write{
    width:1.4rem;
    height:1.4rem;
}

.svg-logo-lubie-small{
    width:1.5rem;
    height:2.5rem;
}

.svg-double-arrow{
    width:3.2rem;
    height:3.2rem;
}

.btn-icon-close{
    width:2.2rem;
    height:2.2rem;
}


/* ********** Svg Social ********** */
.svg-social-facebook{
    width:1.1rem;
    height:2rem;
    padding:0.1rem;
}

.svg-social-youtube{
    width:2.2rem;
    height:1.6rem;
    padding:0.1rem;
}

.svg-social-pinterest{
    width:1.7rem;
    height:2.2rem;
    padding:0.1rem;
}

.svg-social-linkedin{
    width:1.8rem;
    height:2.2rem;
    padding:0.1rem;
}

.svg-social-instagram{
    width:2.3rem;
    height:2.3rem;
    padding:0.1rem;
}

.svg-social-houzz{
    width:1.6rem;
    height:2.5rem;
    padding:0.1rem;
}