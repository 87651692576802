/* ************************************************** */
/* ********** ScrollMagic Animation Custom ********** */
/* ************************************************** */
.scrollmagic-toggle-class{
    opacity:0;
    @include transition(1s);

    &.scrollmagic-active{
        @include transition(2s);
        opacity:1;
    }
}

.sm-animation-bwhite-to-left, .sm-animation-bwhite-to-right{
    position:relative;

    &:before{
        content:" ";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        /*background-color:#f0f5fa;*/
        background-color:$white;
        z-index:99;
        @include transition(1s);
    }

    &.sm-animation-bwhite-to-left-active, &.sm-animation-bwhite-to-right-active{
        &:before{
            width:0;
        }
    }
}

.sm-animation-bwhite-to-right{
    &:before{
        left:auto;
        right:0;
    }
}

.content-background-image .sm-animation-bwhite-to-right, .content-background-image .sm-animation-bwhite-to-left{
    &:before{
        background-color:#f0f5fa;
    }
}